.StoreNotifications {
  /* background-image: url("../../assets/images/GearBackground.png"); */
  background-size: cover;
  /* This ensures the image covers the entire background */
  background-repeat: no-repeat;
  height: 100vh;
}

.AppHeaderDesktop {
  background: #163150;
}

.StoreNotificationsContainer {
  padding: 12px 40px;
}

.ApplicationHealthHeader {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.HeaderCombo {
  margin-left: auto;
  display: flex;

}

.StoreNotificationApplicationCombo {
  margin-left: auto;
}

.FilterSearch,
.CheckBoxGroupContainer,
.notificationTitle {
  display: flex;
  justify-content: space-between;
  /* Aligns the first and last items to the start and end */
}

.notificationTitle {
  margin-top: 10px;
}

.CheckAllBoxContainer {
  width: 10%;
}

.brands {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Optional: Align items vertically if needed */
}

.CheckAllBoxContainer,
.CheckBoxGroupContainer {
  flex: 1;
  /* This will make each container take up equal space */
}

.notificationTitle,
.activeandHistory {
  display: flex;
}

.notificationContainer,
.subjectContainer,
.activeNotification,
.history {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* Stack items vertically */
}

.notificationmsgtitle,
.subjecttitle {
  margin-bottom: 10px;
  /* Add space below the title */
}

.notificationTextArea,
.subjectinput,
.activeNotification,
.history {
  width: 80%;
}

.activeNotification,
.history {
  margin-right: 10px;
}

.submitNotificationBtn {
  background-color: #222222;
  color: white;
  width: 174px;
  height: 48px;
  border-radius: 3px;
  padding: 10px !important;
  margin-top: 20px;
}

.AddBtn {
  background-color: #222222;
  color: white;
  width: 174px;
  height: 48px;
  border-radius: 3px;
  padding: 10px !important;
  margin-top: 20px;
}

.CancelBtn {
  width: 174px;
  height: 48px;
  border-radius: 3px;
  padding: 10px !important;
  margin-top: 20px;
}

.AddBtn:hover {
  background-color: #222222 !important;
  color: white !important;
  width: 174px;
  height: 48px;
  border-radius: 3px;
  padding: 10px !important;
  margin-top: 20px;
}

.submitNotificationBtn:hover {
  background-color: #222222 !important;
  color: white !important;
  width: 174px;
  height: 48px;
  border-radius: 3px;
  padding: 10px !important;
  margin-top: 20px;
}

.notificationDivider {
  border: 1px solid #000000
}

.activeTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #C80F56;
}

.redRound {
  background: url("../../assets/images/svgs/sprite-iservice.svg") no-repeat -603px -94px;
  width: 11px;
  height: 11px;
  display: block;
}

.breadCrumbsLink {
  font-family: HM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #555555;
  text-decoration: none;
}

.breadCrumbsLink:hover {
  color: #555555;
}

.StoreNotificationTitle {
  font-family: HM Sans;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 0px;
  padding-top: 5PX;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  /* Ensure the spinner container takes the full height */
}

@media(max-width:480px) {
  .ApplicationHealthHeader {
    display: block;
  }

  .StoreNotificationsContainer {
    padding: 20px;
  }

  .HeaderCombo {
    display: block !important;
  }

  .datePickerComp {
    width: 100% !important;
  }

  .selectCompContainer {
    width: 100% !important;
  }

  .selectComp {
    width: 100% !important;
    margin-top: 10px;
  }

  .tableContainer {
    width: 100%;
    overflow-x: scroll;
  }

  .submitNotificationBtn {
    width: 100%;
  }

  .StoreNotificationTitle{
    margin-bottom: 10px !important;
  }
}

@media (min-width: 481px) and (max-width:810px) {
  .ApplicationHealthHeader {
    display: block !important;
  }

  .HeaderCombo {
    display: flex;
  }

  .datePickerComp {
    width: 49%;

  }

  .selectCompContainer {
    width: 50% !important;
  }

  .selectComp {
    width: 100% !important;
  }

  .tableContainer {
    width: 100%;
    overflow-x: scroll;
  }

  .StoreNotificationTitle{
    margin-bottom: 10px !important;
  }
}