.mainDiv {
  height: 100vh;
  color: blue;
  padding: 12px 40px;
}

.devider {
  border-top: 1px solid #bbb;
  padding: 15px 0px;
}

.routePageTitleWrapper {
  margin-bottom: 20px;
  color: #555555;
}

.pageTitle {
  font-family: HM Sans;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #333333;
}

.reportCardWrapper {
  display: flex;
  justify-content: flex-start;
  margin: 5px;
}

.alignViewDetailsBtn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-bottom: 18px;
  margin-top: 20px;
}

.breadCrumbsLink {
  font-family: HM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #555555;
  text-decoration: none;

}

.breadCrumbsLink:hover {
  color: #555555;
}

.closeIcon {
  background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -1054px -138px;
  width: 22px;
  height: 22px;
  /* display: block; */
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
}

.currentMeasurePopupContainer {
  width: 455px;
  height: 189px;
  z-index: 1000;
  background-color: white;
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 4px;
  overflow: hidden;
}

.currentMeasurePopupHeading {
  display: flex;
  font-family: HM Sans;
  /* font-size: 18px; */
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #000000;
  padding-bottom: 10px;
}

.tableContainer {
  border: 1px solid gray;
}

.countryHeading {
  width: 22%;
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  padding: 2px 6px;
  font-weight: bold;
  font-size: 14px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.storeIdHeading {
  width: 22%;
  border-bottom: 1px solid gray;
  padding: 2px 6px;
  font-weight: bold;
  font-size: 14px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.remarksHeading {
  width: 60%;
  border-left: 1px solid gray;
  border-bottom: 1px solid gray;
  padding: 2px 6px;
  font-weight: bold;
  font-size: 14px;
  position: relative;
}

.country {
  width: 95px;
  border-right: 1px solid gray;
  padding: 2px 6px;
  font-size: 13px;
}

.storeId {
  width: 95px;
  padding: 2px 6px;
  font-size: 13px;
}

.remarks {
  width: 60%;
  border-left: 1px solid gray;
  padding: 2px 6px;
  font-size: 13px;
}

.deviationDetailsContainer {
  overflow-y: scroll;
  height: 101px;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  /* Ensure the spinner container takes the full height */
}

.filterIcon {
  background: url("../../assets/images/svgs/sprite-icons-desktop.svg") no-repeat -687px -217px;
  width: 57px;
  height: 25px;
  display: block;
}

.filterOptionDiv {
  position: absolute;
  width: 98%;
  background-color: white;
  border: 1px solid gray;
  overflow-y: scroll;
  max-height: 100px;
  right: 0;
  top: 22px;
  display: flex;
  flex-direction: column;
}

.filterOkbtn {
  cursor: pointer;
  margin: 1px 5px;
}

.filterOkDiv {
  position: sticky;
  bottom: 0px;
  background-color: white;
  text-align: right;
  border-top: 1px solid gray;
  font-weight: 500;
}

.filterClearbtn {
  cursor: pointer;
  margin: 1px 5px;
}

.filterBtn {
  border: 1px solid black;
  cursor: pointer
}

.OptionsList{
  padding: 0px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
}