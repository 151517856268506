.container {
  background-image: url("../../assets/images/HomeBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  /* Optional: If you want the background image to stay fixed while scrolling */
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding-bottom: 11% !important;
}

.homepage {
  height: 100%;
  /* height: calc(100vh + 95px); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
}

.headerContainer {
  width: 100vw;
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid #656464;
  margin-bottom: 30px;
}

.statsMain {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

/* .homecontainer {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url("../../assets/images/HomeBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
} */

.homecontainer {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  /* scrollbar-width: none; */
  /* background: linear-gradient(178.73deg, #0A1F37 40.29%, #326FB6 114.13%); */
  background: linear-gradient(180deg, #000 24.76%, #414141 54.98%, #C2C2C2 89.28%, #DBDBDB 95.9%);
}

.homepageContent {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  height: 100%;
}

.divider {
  border-top: 1px solid #656464;
  padding: 15px 0px;
}

.CircleOuterDiv {
  width: 467px;
  height: 466.54px;
  top: 5px;
  left: 6px;
  gap: 0px;
  background: linear-gradient(180deg, #b8b8b8 0%, #ffffff 48.96%, #9a9a9a 100%);
  border-radius: 50%;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 4px 0px #00000040;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  /* Horizontal centering */
  align-items: center;
}

.InnerCircleDiv {
  width: 215.8px;
  height: 215.8px;
  top: 130.48px;
  left: 131.91px;
  gap: 0px;
  margin: 0 auto;
  background: linear-gradient(132.65deg,
      #dbdbdb 14.42%,
      #eeeeee 37.99%,
      #fafafa 62.28%,
      #ffffff 85.85%);
  border-radius: 50%;
  animation-timing-function: ease-out;
  animation-duration: 300ms;
  border: 0.5px solid #d9d9d9;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.backgroundimage {
  width: 100%;
  /* Ensure the image covers the entire width of the container */
  /* Ensure the image covers the entire height of the container */
  object-fit: cover;
  /* Scale the image as large as possible while maintaining aspect ratio */
}

.home_title {
  font-size: 45px;
  font-weight: 700 !important;
  line-height: 65px;
  letter-spacing: 0em;
  margin-bottom: 25px;
}

.home_subtitle_left {
  background: linear-gradient(90deg,
      #ff80e5 0%,
      #ff8db9 25%,
      #ff9f82 50%,
      #ffad58 75%);
  background-clip: text;
  color: transparent;
  display: inline-block;
  font-size: 35px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: left;
}

.home_subtitle_right {
  color: #000000;

  font-size: 35px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0em;
  text-align: left;
}

.home_description {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}

.home_getstarted_btn {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 18px;
  background: #222 !important;
  color: #fff !important;
  border-radius: 0px !important;
  border: 0px !important;
}

.genAIChatIcon {
  background: url("../../assets/images/Chat-logo.svg") no-repeat;
  width: 129px;
  height: 106px;
  position: absolute;
  bottom: 25px;
  right: 10px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.area {
  cursor: pointer;
}

.innerImageCircle {
  width: 475px;
  height: 475px;
  background-image: url("../../assets/images/Subtract.png");
  gap: 0px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  /* Horizontal centering */
  align-items: center;
  position: relative;
  top: 4px;
}

.bigCircleIcon {
  width: 488px;
  height: 488px;
  background-image: url("../../assets/images/svgs/Circle-Image.svg");
  background-repeat: no-repeat;
  margin: 0 auto;
  position: relative;
}

.bigcircle {
  width: 478px;
  height: 478px;
  top: 5px;
  left: 6px;
  gap: 0px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 4px 0px #00000040;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  /* Horizontal centering */
  align-items: center;
  position: relative;
}

.store360 {
  width: 220px;
  height: 220px;
  position: absolute;
  top: 28%;
  left: 27%;
  cursor: pointer;
}

.favourites {
  width: 662px;
  height: 119px;
  top: 619px;
  left: 332px;
  gap: 0px;
  /* border-radius: 143px 0px 0px 0px;
  background: linear-gradient(180deg, #b8b8b8 0%, #ffffff 48.96%, #9a9a9a 100%);
  border-image-source: linear-gradient(90.19deg,
      #a8a8a8 -22.17%,
      #e6e6e6 35.17%,
      #e6e6e6 93.74%); */
  margin: 0 auto;
  display: flex;
  justify-content: center;
  /* Horizontal centering */
  align-items: center;
  /* border-radius: 143px 143px 143px 143px; */
  align-items: center;
  /* border: 4px solid #FFFFFF !important */
  margin-bottom: 30px;
}

.smallcircle {
  width: 219px;
  height: 219px;
  border-radius: 50%;
  position: relative;
  background: linear-gradient(180deg, #E9E9E9 0%, rgba(255, 255, 255, 0) 46.35%, rgba(238, 235, 235, 0.46875) 91.67%);
  border: 0.5px solid #D9D9D9;
  /* box-shadow: 0px 4px 4px 6px #00000040; */
  box-shadow: 0px 4px 20px 6px #747272;
  cursor: pointer;
  top: -2px;
}

.test {
  top: 130.48px;
  left: 131.91px;
  gap: 0px;
  margin: 0 auto;
}

.redRound {
  background: url("../../assets/images/svgs/sprite-iservice.svg") no-repeat -553px -94px;
  width: 13px;
  height: 13px;
  display: block;
}

.colordiv {
  width: 181px;
  height: 97px;
  border: 1px 0px 0px 0px;
  /* background: linear-gradient(180deg, #b8b8b8 0%, #ffffff 48.96%, #9a9a9a 100%); */
  /* border: 1px solid #d7d7d7; */
  /* box-shadow: 0px 4px 4px 0px #00000040; */
  position: absolute;
  /* Make it a cylinder */
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* cursor: pointer; */
}


.innerCircleHeading {
  font-size: 13px;
  margin-bottom: 0px;
  margin-top: 6px;
}

.halfcircle {
  width: 100px;
  /* Adjust width as needed */
  height: 50px;
  /* Half of the width */
  background-color: #007bff;
  /* Change to desired color */
  border-radius: 0 0 50% 50%;
  /* This creates the half circle shape */
  overflow: hidden;
  /* Hides the overflow */
}

.innercurve {
  width: 100%;
  height: 100%;
  background-color: #fff;
  /* Change to match background color */
  border-radius: 50%;
  /* This creates the inward curve */
}

/* .colordiv::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: inherit;
  transform: perspective(100px) rotateY(30deg);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%, 0% 50%);
} */

.InnerCircleIcon {
  background: url("../../assets/images/svgs/sprite-iservice.svg") no-repeat -47px -144px;
  width: 185px;
  height: 126px;
  position: absolute;
  top: 14px;
  left: 54%;
  transform: translateX(-50%);
}

.InnerCirclePara {
  position: absolute;
  top: 73%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: 400;
  line-height: 20.34px;
  text-align: center;
  border-bottom: 0.5px solid #555555;
}

.stockmanagementicon {
  background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -1335px -47px;
  width: 34px;
  height: 34px;
  display: block;
}

.reportsicon {
  background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -854px -46px;
  width: 26px;
  height: 34px;
  display: block;
}

.hardwareicon {
  background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -782px -48px;
  width: 39px;
  height: 28px;
  display: block;
}

.salesmanagementicon {
  background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -647px -43px;
  width: 38px;
  height: 38px;
  display: block;
}

.appsicon {
  background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -716px -46px;
  width: 33px;
  height: 33px;
  display: block;
}

.networkicon {
  background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -980px -40px;
  width: 41px;
  height: 45px;
  display: block;
}

.InnerCircle360Icon {
  background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -1114px -132px;
  width: 65px;
  height: 36px;
  position: absolute;
  bottom: 4%;
  left: 50%;
  transform: translateX(-50%);
}

.staricon {
  background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -797px -138px;
  width: 20px;
  height: 20px;
  display: block;
}

.stariconSmallScrn {
  display: none;
}

.favouritesItem {
  width: 162px;
  height: 119px;
  top: 632px;
  left: 406px;
  gap: 0px;
  border-radius: 18px 18px 18px 18px;
  opacity: 0.6px;
  background: #ffffff;
  box-shadow: 0px 10px 10px 0px #00000040;
  flex: 1;
  /* Distribute remaining space equally among items */
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.favouritesItem:last-child {
  margin-right: 0;
}

.addIcon {
  background: url("../../assets/images/svgs/addIcon.svg") no-repeat;
  display: block;
  width: 107px;
  height: 107px;
}

.addIconSmallScrn {
  display: none;
}

.storeNotificationIcon {
  background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -854px -136px;
  width: 27px;
  height: 26px;
  display: block;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  color: #000000;
}

.applicationHealthIcon {
  background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -918px -131px;
  width: 36px;
  height: 31px;
  display: block;
  margin-bottom: 10px;
  justify-self: center;
  margin-left: auto;
  margin-right: auto;
}

.favouritesItemPara {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  width: 91px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -5px;
}

.allFavouritesItem {
  display: flex;
  gap: 10px;
  padding: 0px 50px;
}

.closeIcon {
  background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -1054px -138px;
  width: 20px;
  height: 20px;
  display: block;
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
}

.criticalAlertIcon {
  background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -993px -138px;
  width: 27px;
  height: 24px;
  display: block;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

/* .smallcircle {
  width: 215.8px;
  height: 215.8px;
  background: linear-gradient(132.65deg,
      #dbdbdb 14.42%,
      #eeeeee 37.99%,
      #fafafa 62.28%,
      #ffffff 85.85%);
  border-radius: 50%;
  position: relative;
} */

.threeSixtydegViewModal {
  width: 90vw;
  height: 88vh !important;
  border-radius: 12px;
  border: 2px 0px 0px 0px;
  border: 2px solid #ffffff;
  top: 10vh !important;
  overflow: scroll;
  background: linear-gradient(180deg, #000000 24.76%, #414141 54.98%, #C2C2C2 89.28%, #DBDBDB 95.9%);
}

.headerTab {
  width: 100%;
  height: 51.14px;
  border-radius: 8px;
  background: linear-gradient(360deg, #ffffff 49.02%, #dddddd 86.27%);
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
}

.modalAppItems {
  width: 100%;
  height: 79px;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 3px;
}

.modalAppItems_Header {
  width: 100%;
  height: 43px;
  top: 1px;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #acacac;
  display: flex;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.34px;
  text-align: left;
  padding-left: 10px;
  background-color: white;
}

.modalAppItems_Content {
  width: 100%;
  height: 36px;
  top: 43px;
  background: black;
  border: 1px solid #959ca4;
  display: flex;
  align-items: center;
  justify-items: center;
}

.searchContent {
  width: 100%;
  height: 73vh;
  margin-top: 8px;
  border-radius: 12px;
  background: linear-gradient(180deg, #000000 24.76%, #414141 54.98%, #C2C2C2 89.28%, #DBDBDB 95.9%);
  border: 1px solid #6a7789;
  overflow-y: auto;
}

.marketListButton {
  background-color: black;
  color: white;
  border-radius: 0px;
  padding: 3px 14px;
  border: 1px solid black; 

}

.marketListButton:hover {
  background-color: white;
  color: black;
  border: 1px solid black; 
}

/* @media (max-width: 2400px) { */
.homepage {
  /* zoom: 0.8; */
}

/* } */

.verticalLine {
  width: 26px;
  height: 0px;
  top: 102.81px;
  left: 716px;
  transform: rotate(-90deg);
  border: 1px solid #000000;
}

.modalContentItemName {
  width: 210px;
  height: 36px;
  border: 1px solid #959CA4;
  padding: 5px;
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.verticalThreeDot {
  background: url("../../assets/images/svgs/sprite-iservice.svg") no-repeat -432px -152px;
  width: 4px;
  height: 16px;
  display: block;
  margin-left: 5px;
}

.rightEnd {
  margin-left: auto;
}

.modalAppItemsIcon_Applications {
  background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -47px -137px;
  width: 22px;
  height: 21px;
  display: block;
  margin-right: 5px;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;

}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: white;
  color: black;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  border: 1px solid black;
  padding-left: 5px;
  padding-right: 5px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 44%;
  margin-left: -70px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 98%;
  left: 50%;
  transform: translateX(-50%);
  /* margin-left: -5px; */
  border-width: 8px;
  border-style: solid;
  border-color: white transparent transparent transparent;
  /* box-shadow: 0px -1px 0px 0px black; */
}

.tooltip .tooltiptext::before {
  content: "";
  position: absolute;
  top: 100%;
  /* top: calc(100%-2px); */
  left: 50%;
  transform: translateX(-50%);
  /* margin-left: -6px; */
  border-width: 8px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  /* box-shadow: 0px -1px 0px 0px black; */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  font-size: 14px;
}

/* .reportTooltip::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent black;
} */

/* .reportTooltip::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 98%;
  transform: translateY(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent white;
  z-index: 2;
} */

.reportTooltip::after {
  content: "";
  position: absolute;
  top: 98%;
  left: 50%;
  transform: translateX(-50%);
  /* margin-left: -5px; */
  border-width: 8px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.reportTooltip::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  /* margin-left: -6px; */
  border-width: 8px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  /* box-shadow: 0px -1px 0px 0px black; */
}



.appsTooltip::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  /* Adjust this value to control the distance from the div */
  /* margin-top: -5px; */
  transform: translateY(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.appsTooltip::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 99%;
  /* Adjust this value to control the distance from the div */
  /* margin-top: -5px; */
  transform: translateY(-50%);
  border-width: 7px;
  border-style: solid;
  border-color: transparent white transparent transparent;
  z-index: 1;
}

.hardwareTooltip::after {
  content: "";
  position: absolute;
  top: 98%;
  left: 50%;
  transform: translateX(-50%);
  /* margin-left: -5px; */
  border-width: 8px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.hardwareTooltip::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  /* margin-left: -6px; */
  border-width: 8px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  /* box-shadow: 0px -1px 0px 0px black; */
}

.store360Tooltip::after {
  content: "";
  position: absolute;
  top: 98%;
  left: 50%;
  transform: translateX(-50%);
  /* margin-left: -5px; */
  border-width: 8px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.store360Tooltip::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  /* margin-left: -6px; */
  border-width: 8px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  /* box-shadow: 0px -1px 0px 0px black; */
}

.feedbackLinkStyle {
  display: block;
  color: white;
  position: absolute;
  rotate: 270deg;
  right: -45px;
  top: 50%;
  border: 1px solid white;
  padding: 6px 19px;
  text-decoration: none;
  border-radius: 8px 8px 0px 0px;
  background: black;
  font-family: HM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.25px;
  text-align: left;
  z-index: 1;
}

.feedbackLinkStyle:hover {
  color: white;
}

.feedbackLinkStyleForMobile {
  display: none;
  color: white;
  position: absolute;
  bottom: 0;
  border: 1px solid white;
  padding: 8px 22px;
  text-decoration: none;
  border-radius: 8px 8px 0px 0px;
  background: black;
  font-family: HM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.25px;
  text-align: left;
}

.feedbackLinkStyleForMobile:hover {
  color: white;
}

.salesManagementTooltip {
  position: relative;
  background-color: white;
  /* Ensure this matches your tooltip background */
}

.salesManagementTooltip::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  /* margin-left: -6px; */
  border-width: 9px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.salesManagementTooltip::after {
  content: "";
  position: absolute;
  top: 97%;
  left: 50%;
  transform: translateX(-50%);
  /* margin-left: -5px; */
  border-width: 8px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.storesStats {
  display: flex;
  gap: 12px;

}

.dropdownDiv {
  margin-right: 8px;
}

.store360ModalInputMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.countryDropDown{
  margin-left: 10px;
}

@media(min-width:395px) and (max-width:480px) {
  .feedbackLinkStyleForMobile {
    display: block;
  }

  .feedbackLinkStyle {
    display: none;
  }
}



@media(max-width:395px) {
  .homepage {
    zoom: 0.75;
  }

  .feedbackLinkStyleForMobile {
    display: block;
  }

  .feedbackLinkStyle {
    display: none;
  }

  .favourites {
    width: 94%;
    height: 143px;
    top: 619px;
    left: 0px;
    gap: 0px;
    border-radius: 0px;
    border: none !important;
    background: none;
    border-image-source: none;
    margin: 0 auto;
    display: block;
    margin-top: 20px;
    border-radius: 0px;
  }

  .allFavouritesItem {
    padding: 0px;
  }

  .addIcon {
    margin-top: 15px !important;
  }
}

@media(min-width:395px) and (max-width:640px) {
  .homepage {
    zoom: 0.78;
  }

  .homepage {
    height: 100vh;
    /* height: calc(100vh + 95px); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .bigcircle {
    left: 0px;
  }

  .favourites {
    width: 100%;
    height: 143px;
    padding-left: 20px;
    padding-right: 20px;
    top: 619px;
    left: 0px;
    gap: 0px;
    border-radius: 0px;
    border: none !important;
    background: none;
    border-image-source: none;
    margin: 0 auto;
    display: block;
    margin-top: 45px;
    border-radius: 0px;
  }

  .favouritesItem {
    height: 144px;
    border: 8px solid #e6e2e2
  }

  .allFavouritesItem {

    padding: 0px;
  }

  .staricon {
    display: none;
  }

  .addIcon {
    margin: auto;
    margin-top: 40px;
  }

}

@media(max-width:1024px) {
  .homepage {
    height: 100%;
    /* height: calc(100vh + 95px); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: auto;
  }

  .dropdownDiv {
    margin-bottom: 8px;
  }
}

/* New css*/
/* Mobile and Tablet responsive styles */
/* Mobile and Tablet responsive styles */
@media (max-width: 1024px) {
  .headerTab {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    /* Make child elements take full width */
    padding: 10px;
    background: linear-gradient(360deg, #ffffff 49.02%, #dddddd 86.27%);
  }

  .modalcheckboxes {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
    /* Space between elements */
  }

  .modalcheckboxes .ant-m-group-item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    /* Space between checkboxes */
  }

  .verticalLine {
    display: none;
    /* Hide the vertical line in responsive views */
  }

  .applicationHealthStyle {
    width: 100%;
    margin-bottom: 10px;
    /* Space between elements */
  }

  .marketListButton {
    width: 100%;
    margin-bottom: 10px;
    /* Space between elements */
    background-color: black;
    color: white;
  }
}

/* Mobile and Tablet CSS */
@media (max-width: 1024px) {
  .modalAppItems {
    width: 100%;
    margin-bottom: 10px;
    height: auto;
  }

  .modalAppItems_Header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.34px;
    text-align: left;
    background-color: white;
    color: #fff;
    border-radius: 4px 4px 0 0;
  }

  .modalAppItems_Content {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: black;
    border: 1px solid #959ca4;
    border-top: none;
    border-radius: 0 0 4px 4px;
    height: auto;
  }

  .modalContentItemName {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-top: none;
    color: #fff;
    background-color: #2c4466;
    width: 100%;
  }

  .verticalThreeDot {
    background: url("../../assets/images/svgs/sprite-iservice.svg") no-repeat -432px -152px;
    width: 4px;
    height: 16px;
    display: block;
    margin-left: 5px;
  }

  .rightEnd {
    margin-left: 0;
  }
}

@media(max-width:1080px) {
  .storesStats {
    display: none;
  }

  .statsMain {
    display: none;
  }
}

@media(max-width:600px) {
  .headerContainer {
    border-bottom: none;
  }
  .store360ModalInputMain {
    display: block;
  }
  
  .countryDropDown{
    margin-left: 0px;
  }
}