.StoreNotifications {
  /* background-image: url("../../assets/images/GearBackground.png");
  background-size: cover; */
  /* This ensures the image covers the entire background */
  /* background-repeat: no-repeat; */
  height: 100vh;
}

.AppHeaderDesktop {
  background: #163150;
}

.StoreNotificationsContainer {
  padding: 12px 40px;
}

.StoreNotificationHeader {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.StoreNotificationApplicationCombo {
  margin-left: auto;
}

.StoreNotificationTitle {
  font-family: HM Sans;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 0px;
}

/* .FilterSearch, */
.CheckBoxGroupContainer,
.notificationTitle {
  display: flex;
  justify-content: space-between;
  /* Aligns the first and last items to the start and end */
}

.FilterSearch {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  /* Adjust gap as neeed */
  justify-content: space-between;
  padding-top: 10px;
}

.FilterSearch>* {
  flex: 1 1 228px;
  /* Grow to fit available space, but with a base width of 228px */
}

.breadCrumbsLink {
  font-family: HM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #555555;
  text-decoration: none;
}

.breadCrumbsLink:hover {
  color: #555555;
}

.notificationCollapseContainer {
  height: 551px;
  overflow: scroll;
}

@media (max-width: 768px) {
  .FilterSearch {
    flex-direction: column;
    gap: 8px;
    /* Smaller gap on mobile to reduce height */
  }

  .FilterSearch>* {
    width: 100% !important;
    flex: 1 1 100%;
  }
}

.notificationTitle {
  margin-top: 10px;
}

.CheckAllBoxContainer {
  width: 10%;
}

.brands {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Optional: Align items vertically if needed */
}

.CheckAllBoxContainer,
.CheckBoxGroupContainer {
  /* This will make each container take up equal space */
}

@media (max-width: 768px) {
  .brands {
    flex-direction: column;
    gap: 10px;
    /* Smaller gap on mobile to reduce height */
  }

  .CheckAllBoxContainer {
    width: 100%;
    justify-content: space-between;
  }
}

.notificationTitle,
.activeandHistory {
  display: flex;
}

.notificationContainer,
.subjectContainer,
.activeNotification,
.history {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* Stack items vertically */
}


.filtersearchmsgtitle {
  padding-top: 10px;
}

.notificationmsgtitle,
.subjecttitle {
  margin-bottom: 10px;
  /* Add space below the title */
}

.notificationTextArea,
.subjectinput,
.activeNotification,
.history {
  width: 80%;
}

.activeNotification,
.history {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .notificationTitle {
    flex-direction: column;
    /* Stack containers vertically on small screens */
    gap: 10px;
    /* Smaller gap for mobile */
  }

  .notificationContainer,
  .subjectContainer {
    width: 100%;
    /* Full width for each container */
  }

  .notificationTextArea,
  .subjectinput {
    width: 100%;
    /* Ensure input fields take full width */
  }

  .activeandHistory {
    flex-direction: column;
    /* Stack active and history sections vertically */
    gap: 10px;
    /* Smaller gap for mobile */
  }

  .activeNotification,
  .history {
    width: 100%;
    /* Full width for each section */
  }
}

.submitNotificationBtn {
  background-color: #222222;
  color: white;
  width: 174px;
  height: 48px;
  border-radius: 4px;
  padding: 10px !important;
  margin-top: 53px;
}

.submitNotificationBtn:hover {
  background: #555555 !important;
  color: #fff !important;
  opacity: 1;
  border: none;
}

.notificationDivider {
  border: 1px solid #b4b4b4;
}

.activeTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: black;
}

.brands {
  display: flex;
  gap: 20px;
  /* Adjust gap as needed */
  padding-bottom: 10px;
}

.CheckAllBoxContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  /* Space between "All Brands" checkbox and divider */
}

.datePickersContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  /* Adjust as needed */
  width: 80%;
  margin-top: 10px;
}

.modalDatePicker {
  padding: 4px 1px;
  border: 1px solid #d3cbcb;
  border-radius: 5px;
}

.ModalDatePickersContainer {
  justify-content: space-between;
  margin-bottom: 16px;
  /* Adjust as needed */
  width: 80%;
  margin-top: 10px;
}

.datePickerItem {
  display: flex;
  flex-direction: column;
}

.datePickerLabel {
  margin-bottom: 4px;
  /* Adjust spacing between label and DatePicker */
}

input[type=radio] {
  border: 0px;
  width: 16px;
  height: 16px;
  /* color: #000000; */
  background-color: #3c3b3b;
  accent-color: #3c3b3b
}


@media (max-width: 768px) {
  .datePickersContainer {
    flex-wrap: wrap;
    width: 100%;
  }

  .datePickerItem {
    max-width: 100%;
    /* On mobile, each date picker takes full width */
    margin-bottom: 16px;
    /* Add margin between items on mobile */
  }
}

.spinnerContainer {
  margin: auto;
}

.pageLoader {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notificationitem {
  width: 100%;
  position: relative;
}

.notificationmessage {
  display: block;
  margin-right: 120px;
  /* Adjust based on button group width */
}

.buttongroup {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.iconbutton {
  background: none !important;
  border: none !important;
  cursor: pointer;
  padding: 5px;
  font-size: 17px;
  /* Adjust the size as needed */
  padding-left: 10px;
}

.iconbutton:hover {
  color: black;
  /* Change to desired hover color */
}

.customOkBtnStyle{
  background-color: black;
}

.customOkBtnStyle:hover{
  background-color: #555555 !important;
  opacity: 1;
}

.customCancelBtnStyle{
  background-color: white;
  color: black;
  border: 1px solid black;
}

.customCancelBtnStyle:hover{
  border: 1px solid black !important;
  color: black !important;
  opacity: 1;
}

@media(max-width:874px) {
  .divider {
    display: none;
  }
}

@media(min-width:769px) and (max-width:905px) {
  .UntilFurtherText {
    font-size: 11px;
  }
}