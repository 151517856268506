.StoreNotifications {
  /* background-image: url("../../assets/images/GearBackground.png");
  background-size: cover; */
  /* This ensures the image covers the entire background */
  /* background-repeat: no-repeat;
  height: 100vh; */
}

.AppHeaderDesktop {
  background: #163150;
}

.StoreNotificationsContainer {
  padding: 12px 40px;
}

.StoreNotificationTitle {
  font-family: HM Sans;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 0px;
  /*line-height: 20px;
  text-align: left; */
}

.ApplicationHealthHeader {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.HeaderCombo {
  margin-left: auto;
}

.StoreNotificationApplicationCombo {
  margin-left: auto;
}

.FilterSearch,
.CheckBoxGroupContainer,
.notificationTitle {
  display: flex;
  justify-content: space-between;
  /* Aligns the first and last items to the start and end */
}

.notificationTitle {
  margin-top: 10px;
}

.CheckAllBoxContainer {
  width: 10%;
}

.brands {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Optional: Align items vertically if needed */
}

.CheckAllBoxContainer,
.CheckBoxGroupContainer {
  flex: 1;
  /* This will make each container take up equal space */
}

.notificationTitle,
.activeandHistory {
  display: flex;
}

.notificationContainer,
.subjectContainer,
.activeNotification,
.history {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* Stack items vertically */
}

.notificationmsgtitle,
.subjecttitle {
  margin-bottom: 10px;
  /* Add space below the title */
}

.notificationTextArea,
.subjectinput,
.activeNotification,
.history {
  width: 80%;
}

.activeNotification,
.history {
  margin-right: 10px;
}

.submitNotificationBtn {
  background-color: black;
  color: white;
  width: 174px;
  height: 48px;
  border-radius: 4px;
  padding: 10px !important;
  margin-top: 20px;
}

.submitNotificationBtn:hover {
  background: #555555 !important;
  color: #fff !important;
  opacity: 1;
  border: none;
}

.AddBtn {
  background-color: black;
  color: white;
  width: 50%;
  height: 34px;
  border-radius: 0px;
  padding: 10px !important;
  /* margin-top: 20px; */
  font-size: 12px !important;
}

.AddBtn:hover {
  background: #555555 !important;
  color: #fff !important;
  opacity: 1;
  border: none;
  border-radius: 0px !important;
}

.CancelBtn {
  width: 50%;
  height: 34px;
  border: 1px solid black;
  border-radius: 0px;
  padding: 10px !important;
  /* margin-top: 20px; */
  font-size: 12px !important;
  font-weight: 600;
  ;
}

.CancelBtn:hover {
  color: black !important;
  border-color: #000000 !important;
}

.notificationDivider {
  border: 1px solid #000000
}

.activeTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #C80F56;
}

/* .applicationHealthCardDiv{
  background-image: linear-gradient(#F4F3F3, #FFFFFF, #FFFFFF);
  border: 1px solid  #CCCCCC
} */

.addMoreAppPopoverSmallScrn {
  display: none;
}

.breadCrumbsLink {
  font-family: HM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #555555;
  text-decoration: none;
}

.breadCrumbsLink:hover {
  color: #555555;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  /* Ensure the spinner container takes the full height */
}

.addAppInputSearch {
  width: 98%;
  margin: 10px 2px;
}

.addAppsListDiv {
  height: 150px;
  overflow: scroll;
  margin: 0 5px;
}

/* Custom Select styling */
.custom-select .ant-select-arrow {
  /* display: flex;
  align-items: center; */
  border-left: 1px solid black !important;
  padding-left: 6px !important;
  height: 14px !important;
}



.custom-select .ant-select-arrow::before {
  content: '';
  display: inline-block !important;
  width: 2px !important;
  height: 16px !important;
  background-color: black !important;
  margin-right: 8px;
}

@media(max-width:600px) {
  .ApplicationHealthHeader {
    /* flex-direction: column; */
    display: block;
    align-items: start;
  }

  .StoreNotificationTitle {
    margin-bottom: 8px;
    margin-top: 8px;


  }

  .HeaderCombo {
    margin-left: 0px;
    width: 100% !important;
  }

  .addMoreApplicationBtnContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .addMoreAppPopoverSmallScrn {
    display: flex;
  }

  .addMoreAppPopoverBigScrn {
    display: none;
  }

  .submitNotificationBtn,
  .submitNotificationBtn:hover {
    width: 100%;
    justify-content: center;
  }
}