.mainDiv {
    height: 100vh;
    color: blue;
    padding: 12px 40px;
}

.routePageTitleWrapper {
    margin-bottom: 20px;
    color: #555555;
}

.breadCrumbsLink {
    font-family: HM Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #555555;
    text-decoration: none;
}

.breadCrumbsLink:hover {
    color: #555555;
}

.feedbackTitle {
    font-family: 'HM Sans';
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #333333 !important;
    padding-top: 5px;
}

.inputContainer {
    display: flex;
    margin-bottom: 20px;
}

.inputStyle {
    padding: 5px 12px;
    width: 228px;
    border-radius: 4px;
    border: 1px solid gray;
    color: black;
}

.msgInput {

    width: 723px;
    padding: 5px 12px;
    border-radius: 4px;
    border: 1px solid gray;
    color: black;

}

.HeaderCombo {
    width: 228px
}

.submitBtn {
    padding: 8px 12px;
    width: 228px;
    background-color: black;
    color: white;
    border: none;
    margin-top: 30px;
    border-radius: 2px;
}

.submitBtn:hover {
    background: #555555 !important;
    color: #fff !important;
    opacity: 1;
    border: none;
}

@media (max-width: 480px) {
    .mainDiv {
        padding-bottom: 50px !important;
    }

    .feedbackTitle {
        margin-bottom: 10px;
    }

}

@media (max-width: 556px) {
    .HeaderCombo {
        width: 100% !important
    }

    .inputStyle {
        width: 100% !important;
        margin-bottom: 8px;
    }
}

@media(min-width:557px) and (max-width:645px) {
    .HeaderCombo {
        margin-bottom: 2px;
    }

    .inputStyle {
        margin-bottom: 8px;
    }
}


@media(max-width:646px) {
    .inputContainer {
        display: block;
    }
}