.answerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px;
  border-radius: 12px;
  background: #ffffff;
  margin-bottom: 8px;
  border: 2px solid #cce0d9;
}

.answerTextButtonDiv {
  width: 100% !important;
}

.answerTextButton {
  width: 20% !important;
  border-radius: 5px;
  background: linear-gradient(180deg,
      #79abc2 0%,
      #6f9dbe 20.31%,
      #4e6fa5 40.63%,
      #354c86 58.85%,
      #263463 79.17%,
      #202450 100%);
  color: #ffffff !important;
}

.answerTextButtonCancel {
  width: 20% !important;
  border-radius: 5px;
  margin-left: 6px;
}

.answerText {
  font-family: "HM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #323130;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 11px;
  white-space: normal;
  word-wrap: break-word;
  overflow-x: auto;
}

.answerFooter {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  justify-content: space-between;
}

.answerDisclaimerContainer {
  justify-content: center;
  display: flex;
}

.answerDisclaimer {
  font-family: "HM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  display: flex;
  align-items: center;
  text-align: center;
  color: #707070;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.citationContainer {
  margin-left: 10px;
  font-family: "HM Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: #115ea3;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 6px;
  gap: 4px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}

.citationContainer:hover {
  text-decoration: underline;
  cursor: pointer;
}

.citation {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 14px;
  height: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  flex: none;
  flex-grow: 0;
  z-index: 2;
  font-family: "HM Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: #424242;
  cursor: pointer;
}

.citation:hover {
  text-decoration: underline;
  cursor: pointer;
}

.accordionIcon {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-top: 4px;
  color: #616161;
  font-size: 10px;
}

.accordionIcon:hover {
  cursor: pointer;
}

.accordionTitle {
  margin-right: 5px;
  margin-left: 10px;
  font-family: "HM Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #616161;
}

.accordionTitle:hover {
  cursor: pointer;
}

.clickableSup {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 14px;
  height: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  flex: none;
  order: 2;
  flex-grow: 0;
  z-index: 2;
  font-family: "HM Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: #424242;
  cursor: pointer;
}

.clickableSup:hover {
  text-decoration: underline;
  cursor: pointer;
}

sup {
  font-size: 10px;
  line-height: 10px;
}

.gradient-bg {
  background: linear-gradient(to right, #f76707, #ff2e63);
}

.titleIcon {
  background: url("../../assets/images/svgs/ai-companion-sprite.svg") no-repeat -155px -202px;
  width: 56px;
  height: 56px;
  display: inline-flex;
  align-items: center;
  margin-right: 13px;
}

/* Styles for devices with a maximum width of 767 pixels (typical mobile devices) */
@media only screen and (max-width: 767px) {
  .answerIcon {
    width: 10% !important;
  }

  .answerContainerCol {
    width: 85% !important;
    padding-bottom: 10px;
  }
}

/* Styles for devices with a minimum width of 768 pixels and a maximum width of 1024 pixels (typical iPads) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {}

/* Styles for devices with a minimum width of 1025 pixels (typical desktop devices) */
@media only screen and (min-width: 1025px) {}

.ansInnerCnt {
  overflow: scroll;
  max-width: 100%;
}

.quickFacts {
  font-weight: 400;
  font-size: 16px;
  line-height: 23.25px;
  color: #7d7d7d;
}

.loadingimgdiv {
  display: flex;
  /* Use flexbox layout */
  align-items: center;
  /* Align items vertically */
  justify-content: center;
  /* Align items horizontally */
}

.ansInnerCnt {
  width: 100%;
}

/* .loadingimg {
  width: 20%;
} */
.loadingimg {
  max-width: 100%;
  padding: 0px 12px;
  width: 70%;
}

.MacAddressForm {
  width: 467px;
  height: 224px;
  gap: 0px;
  border: 3px 0px 0px 0px;
  color: #323130;
  border: 3px solid #CCE0D9;
  padding: 25px;
}

.MacAddressInnerDiv {}

.formheading {
  font-size: 16px;
  font-weight: 400;
  line-height: 23.25px;

}
.submitbutton{
  background-color: #84a498;
  color: #fff;
  width: 45%;
  margin-right: 5px;
}
.submitbutton:hover{
  background-color: #7e948b;
  color: #fff;
  width: 45%;
  margin-right: 5px;
}
