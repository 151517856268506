.login-main-div {
    background-image: url(../../assets/images/background.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    max-width: 100vw;
  }