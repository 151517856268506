.mainDiv {
    height: 100vh;
    color: blue;
    padding: 12px 40px;
}

.devider {
    border-top: 1px solid #bbb;
    padding: 10px 0px;
}

.routePageTitleWrapper {
    padding-bottom: 20px;
    color: #555555;
}

.storeScannerTitle {
    font-family: HM Sans;
    font-size: 25px !important;
    font-weight: 500;
    margin-bottom: 0px;
    color: #333333;
}

.checkboxWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    margin: 8px;
    margin-bottom: 12px;
}

.antCheckboxGroup {
    width: auto;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: space-between;
    gap: 20px;
}

.filterTitle {
    font-family: "HM Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #555555;
    padding-right: 5px;
}


.breadCrumbsLink {
    font-family: HM Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #555555;
    text-decoration: none;
}

.breadCrumbsLink:hover {
    color: #555555;
}

.headerDiv{
    display: flex;
}

.tablesContainer {
    display: flex;
    justify-content: space-between;
}

.smartphonesTableContainer {
    /* width: 58% !important; */
    overflow-x: scroll !important;
    overflow-y: hidden;
}

/* .storesNotSyncedtableContainer {
    width: 40% !important;
    overflow-x: scroll !important;
} */

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    /* Ensure the spinner container takes the full height */
}

.divisionHeading {
    font-size: 16px;
    color: #000000;
    margin-bottom: 30px;
}

.chartHeading {
    font-size: 16px;
    color: #000000;
    margin-bottom: 30px;
}


.secondHalfContainer {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    margin-bottom: 30px;
}

.storesNotSyncedtableContainer {
    width: 58%;
}

.chartDiv {
    width: 40%;
}

.ongoingAlert {
    color: #222222;
    border: 1px solid #CCCCCC;
    padding: 15px 30px;
    border-left: 12px solid #A20505;
    margin-bottom: 20px;
}

.redLabel {
    position: absolute;
    right: 14%;
    color: #CB3011;
    font-size: 18px;
    top:20%
}

.greenLabel {
    position: absolute;
    left: 14%;
    color: #076C35;
    font-size: 18px;
    top:20%

}

.chartHeadingMobile{
    display: none;
}

.chartAndLabelContainer{
    margin-top: 100px;
}

@media(max-width:480px) {
    

    .antCheckboxGroup {
        width: auto;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        align-content: space-between;
        gap: 10px;
    }

    .filterTitle {
        width: 100%;
        font-family: "HM Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        color: #555555;
        padding-bottom: 15px;
    }

    
    
}

@media(max-width:600px) {

.redLabel {
    position: absolute;
    right: 2%;
    color: #CB3011;
    font-size: 30px
}

.greenLabel {
    position: absolute;
    left: 2%;
    color: #076C35;
    font-size: 30px
}
}


@media(max-width:1250px) {
    .headerDiv{
        display: block;
    }
    
    .checkboxWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        margin: 8px;
    }
}

@media(max-width:1024px) {
   

  

    .secondHalfContainer{
        display: block;
    }

    .storesNotSyncedtableContainer {
        width: 100%;
        margin-bottom:35px;
    }
    
    .chartDiv {
        width: 100%;
        margin: 20px auto;
    }

    .chartHeading {
      display: none;
    }

    .chartHeadingMobile{
        display: block;
        font-size: 16px;
        color: #000000;
        margin-bottom: 30px;
    }

}

@media(min-width:601px) and (max-width:1024px) {
    .redLabel {
        position: absolute;
        right: 15%;
        color: #CB3011;
        font-size: 30px
    }

    .greenLabel {
        position: absolute;
        left: 15%;
        color: #076C35;
        font-size: 30px
    }
}

@media(max-width:1307px) {
    .tablesContainer{
        display: block;
    }

    .smartphonesTableContainer {
        width: 100% !important;
    }

    /* .storesNotSyncedtableContainer{
        width: 100% !important;
    } */
}

@media(min-width:1307px) {
    .heading{
        width:40%
    }
}