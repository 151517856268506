.datepickerInputcontainer{
    width: 180px;
    border-radius: 5px;
}

.datePickerStyle{
    border:"1px solid #979797"; 
    color:"#555555"; 
    font-family:"HM Sans"
}

@media(max-width:480px){
    .datepickerInputcontainer{
        width: 100% !important;
    }

    .datePickerStyle{
        width: 100% !important;
    }
}
