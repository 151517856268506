.mainDiv {
    height: 100vh;
    color: blue;
    padding: 12px 40px;
}

.routePageTitleWrapper {
    margin-bottom: 20px;
    color: #555555;
}

.breadCrumbsLink {
    font-family: HM Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #555555;
    text-decoration: none;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}

.headerTitle {
    font-family: 'HM Sans';
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #333333 !important;
    padding-top: 5px;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    /* Ensure the spinner container takes the full height */
  }
  