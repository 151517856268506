.mainContainer {
    width: 206px;
    height: 125px;
    background: white;
    justify-content: center;
    align-items: center;
    /* border: 2px solid black; */
    border-radius: 15px;
    /* margin-bottom: 10px; */
}

.countTypographyWrapper {
    width: 191px;
    height: 76px;
    background: linear-gradient(180deg, #000000 32.89%, #B9B9B9 107.89%);
    margin: 4px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid black; */
    border-radius: 14px;
}

.countTypography {
    /* font-family: "HM Slussen Condensed";
    font-size: 35px;
    font-weight: 400;
    letter-spacing: 0.03em;
    text-align: center;*/
    color: #ffffff;
    font-family: HM Slussen Condensed;
    font-size: 45px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.03em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.textLabel {
    font-family: "HM Slussen Condensed";
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 0.03em;
    text-align: center;
    color: #ffffff;
}

.labelWrapper {
    /* margin: 10px 25px; */
    font-size: 16px;
    text-align: center;
    font-family: HM Slussen Condensed;
font-size: 16px;
font-weight: 400;
line-height: 22px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}