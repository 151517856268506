.mainDiv {
    height: 100vh;
    color: blue;
    padding: 12px 40px;
}

.routePageTitleWrapper {
    padding-bottom: 20px;
    color: #555555;
}

.breadCrumbsLink {
    font-family: HM Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #555555;
    text-decoration: none;
}

.breadCrumbsLink:hover {
    color: #555555;
}

.heading {
    width: 40%;
    margin-bottom: 10px;
}

.assetInsightsTitle {
    font-family: HM Sans;
    font-size: 25px !important;
    font-weight: 500;
    margin-bottom: 0px;
    color: #333333;
}

.inputContainerDiv {
    display: flex;
    padding-top: 5px;
    /* gap: 5px; */
}

.dropDownContainerDiv {
    width: 160px;
    margin-left: "10px";
}

.countryDropDown {
    margin-left: 3px;
    margin-right: 10px;
}

.insightsHeader {
    display: flex;
    justify-content: space-between;
}

.cards {
    display: flex;
    justify-content: space-between;
    gap: 20px
}

.cardContainer {
    border: 1px solid gray;
    border-radius: 12px;
    padding: 8px 7px;
    width: 100%;
    /* padding-bottom: 6px; */
}

.cardMainHeding {
    color: #555555;
}

.innerCardContainer {
    border: 1px solid #676767;
    /* box-shadow: 0px 2px 4px 0px #00000040; */
    box-shadow: 0px 4px 4px 0px #00000040;
    /* width: 138px; */
    width: 50%;
    height: 86px;
    background: black;
    border-radius: 8px;
}

.innerCard {
    display: flex;
    gap: 10px;
    width: 100%;
}

.cardValue {
    background: linear-gradient(180deg, #000000 61.9%, #B9B9B9 126.19%);
    border: 0.5px solid #828282;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    margin: 4px;
    border-radius: 8px;
    font-size: 18px;
}

.cardLabel {
    color: white;
    font-weight: lighter;
    text-align: center;
    margin-top: 8px;
}

.donutChartContainer {

    position: relative;
    width: 300px;
    height: 300px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    /* border:1px solid gray */
    margin: auto;
}

.columnChartContainer {
    position: relative;
    min-width: 300px;
    height: 300px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    /* border:1px solid gray */
    margin: auto;
}

.donutChartTotalCount {
    position: absolute;
    top: 135px;
    color: rgb(85, 85, 85);
    font-size: 22px;
}

.donutChartTitle {
    position: absolute;
    top: 10px;
    left: 20px;
    z-index: 10;
    color: black;
}

.graphContainer {
    border: 1px solid gray;
    border-radius: 12px;
    width: 100%
}

.columnGraphContainer {
    position: relative;
    border: 1px solid gray;
    border-radius: 12px;
    min-width: 400px;
    width: 100%
}

.pieChartlegendMain {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.pieChartLegendDot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.chartTItile {
    color: black;
    margin: 10px 0px;
    margin-left: 20px;
    font-size: 17px;
}

.secondRow {
    display: flex;
    justify-content: space-around;
    margin: 15px 0px;
    gap: 20px;
    width: 100%;
    overflow-x: scroll;
    padding-bottom: 5px;
    /* scrollbar-width: none; */
}

.secondRow::-webkit-scrollbar {
    height: 8px; /* Set the height of the horizontal scrollbar */
    margin-top: 5px;
}

.pageLoader {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tableMainDiv {
    color: black;
    margin: 20px 0px 10px 0px;
}

.tableHeaderDiv {
    display: flex;
    background: #434343;
    color: white;
    border-radius: 2px;
}

.tableHeaderLeft {
    width: 97%;
    text-align: center;
    padding: 8px 0px;
}

.tableHeaderRight {
    width: 100%;
    text-align: center;
    padding: 8px 0px;
}

.tabledatRowDiv {
    display: flex;
    width: 100%;
}

.tableBodyDiv {
    height: 260px;
    overflow-y: scroll;
    font-size: 12px;
    scrollbar-color: #D6E0EB white;
   
}

.tabledataleft {
    width: 100%;
    text-align: center;
    padding: 5px 0px;
    border-right: 1px solid #B0C6DA
}

.tabledataRight {
    width: 100%;
    text-align: center;
    padding: 5px 0px;
}

.oddTableDatRow {
    background: #DCDCDC;
    border-radius: 2px;
}

.noDataDiv {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 11px;
}

.noTableDataDiv {
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 11px;
}

.cardHeadingAndIconDiv {
    display: flex;
    gap: 5px;
    /* margin-bottom: 5px; */
}

.lastSyncData{
    color: black;
    margin-bottom: 5px;
}

.BBScannerIcon {
    background: url('../../assets/images/svgs/sprite-iservice-desktop.svg') no-repeat -984px -223px;
    width: 16px;
    height: 18px;
}

.smartphonesIcon {
    background: url('../../assets/images/svgs/sprite-iservice-desktop.svg') no-repeat -952px -225px;
    width: 12px;
    height: 15px;
}

.tabletsIcon {
    background: url('../../assets/images/svgs/sprite-iservice-desktop.svg') no-repeat -1019px -224px;
    width: 13px;
    height: 15px;
}

.RfidIcon {
    background: url('../../assets/images/svgs/sprite-iservice-desktop.svg') no-repeat -1051px -222px;
    width: 17px;
    height: 17px;
}

.storesWithOutSmartPhoneMain {
    padding: 10px 20px;
    width: 100%;
    min-width: 350px;
}

.columnChartMain {
    display: flex;
    gap: 20px;
}

.smartPhoneByCountryChartDiv {
    width: 50%;
    border: 1px solid rgb(175, 192, 207);
    border-radius: 12px;
}

.smartPhoneUtilByCountryChartDiv {
    width: 50%;
    border: 1px solid rgb(175, 192, 207);
    border-radius: 12px;
}

.smartPhoneByCountryChartHeading {
    margin: 10px 20px;
    color: black;
    font-size: 16px;
}

.smartPhoneUtilByCountryChartHeading {
    margin: 10px 20px;
    color: black;
    font-size: 16px;
}

.smartPhoneVsBBTrendMain {
    margin: 20px 0px;
    border: 1px solid rgb(175, 192, 207);
    border-radius: 12px;
}

.smartPhoneVsBBTrendChartMain {
    display: flex;
    justify-content: space-between;
    color: black;
    font-size: 16px;
    margin: 10px 20px 0px;

}

.smartphoneVsBBSyncData{
    margin: 5px 20px 40px;
}

.lastSyncSmartphonesData{
    color: white;
    margin-bottom: 5px;
}

@media(max-width:800px) {
    .insightsHeader {
        display: block;
    }

    .inputContainerDiv {
        display: block;
    }

    .dropDownContainerDiv {
        width: 100%;
        margin-left: 0px;
        margin-bottom: 10px;
    }

    .cards {
        display: block;
    }

    .cardContainer{
        margin-bottom: 6px;
    }

    .secondRow {
        display: block;
    }

    .columnGraphContainer {
        margin-top: 10px;
        margin-bottom: 10px;
        min-height: 300px;
    }

    .noDataDiv {
        height: 300px;
    }

    .graphContainer {
        margin-bottom: 10px;
    }

    .donutChartContainerVersionDetails {
        margin-bottom: 25px;
    }

    .storesWithOutSmartPhoneMain {
        min-width: 100%;
        width: 100%;
    }

    .columnChartMain {
        display: block;
    }

    .smartPhoneUtilByCountryChartDiv {
        width: 100%;
    }

    .smartPhoneByCountryChartDiv {
        width: 100%;
        margin-bottom: 10px;
    }

    .smartPhoneByCountryChartHeading {
        font-size: 14px;
    }

    .smartPhoneUtilByCountryChartHeading {
        font-size: 14px;
    }

    .smartPhoneVsBBTrendChartMain {
        display: block;
        font-size: 14px;
    }

    
}