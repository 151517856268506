.headertitle {
  color: white;
  font-size: 22px;
}

.ChatDropDownToggle,
.ChatDropDownTo:hover {
  color: white !important;
  background-color: #000 !important;
  border-color: #000 !important;
}

.ChatDropDownTo:hover {
  color: white !important;
  background-color: #000 !important;
  border-color: #000 !important;
}

.headerlogo {
  background: url("../images/svgs/sprite-iservice.svg") no-repeat -36px -85px;
	width: 140px;
	height: 26px;
  display: inline-flex;
  align-items: center;
}