.MobileHomePage {
  height: 100vh;
  background-size: cover;
  position: relative;
  color: #fff;
  font-family: "HM Sans", sans-serif;
  background-image: url(../../assets/images/NetworkBackground.svg) !important;
  background-size: cover;
}
.container {
  display: flex;
  justify-content: center; /* Horizontally center the items */
  align-items: center; /* Align items to the top */
  height: 100vh; /* Set the container height to 100% of viewport height */
}

.item {
  padding: 50px;
  /* Styles for your grid items */
}
.Content {
  position: absolute;
  top: 10%;
  /* left: 50%;
    transform: translate(-50%, -50%); */
  text-align: center;
  width: 100%;
}

.homeCard {
  color: black;
  display: flex !important;
  /* background-color: #c2cbda !important; */
  border: 5px solid #9eadc6 !important;
  align-items: center !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  justify-content: center !important;
  background: linear-gradient(180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 95.14%) !important;
  width: 350px !important;
  height: 250px !important;
  top: 249px;
  left: 22px;
  gap: 0px;
  border-radius: 18px !important;
  opacity: 0.6px !important;
  margin-bottom: 12px;
}
.HomeGrid a{
  text-decoration: none;
}

.HomeGrid {
  padding: 27px;
}

.HomeGrid a {
  padding: 0px !important;
}

.StoreButton {
  background: linear-gradient(180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.5) 95.14%) !important;
  color: black !important;
  border: 2px solid #9eadc6 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  padding: 15px 20px !important;
  margin: 10px !important;
  border-radius: 31px !important;
  width: 70% !important;
}

.CenteredButton {
  display: flex;
  justify-content: center;
}

.InStoreCareIcon {
  background: url("../../assets/images/svgs/sprite-iservice.svg") no-repeat -169px -29px;
  width: 33px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  /* margin-right: 13px; */
}

.SmartphoneIcon {
  background: url("../../assets/images/svgs/sprite-iservice.svg") no-repeat -236px -29px;
  width: 26px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  /* margin-right: 13px; */
}

.VisitorCountIcon {
  background: url("../../assets/images/svgs/sprite-iservice.svg") no-repeat -294px -29px;
  width: 48px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  /* margin-right: 13px; */
}

.TBDIcon {
  background: url("../../assets/images/svgs/sprite-iservice.svg") no-repeat -294px -29px;
  width: 48px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  /* margin-right: 13px; */
}
.StoreImage {
  background: url("../../assets/images/svgs/sprite-iservice.svg") no-repeat -47px -144px;
	width: 185px;
	height: 126px;
  display: inline-flex;
  align-items: center;
  /* margin-right: 13px; */
}
.TeamsIcon {
  background: url("../../assets/images/svgs/sprite-iservice.svg") no-repeat -693px -136px;
	width: 72px;
	height: 48px;
  display: inline-flex;
  align-items: center;
  /* margin-right: 13px; */
}
.ServiceDeskImage {
  background: url("../../assets/images/svgs/sprite-iservice.svg") no-repeat -693px -136px;
	width: 72px;
	height: 48px;
  display: inline-flex;
  align-items: center;
  /* margin-right: 13px; */
}
.socialElement{
  width: 50%;
}
.socialElement a{
  color: #fff;
  text-decoration: none;
}
