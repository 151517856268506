.mainDiv {
  height: 100vh;
  color: blue;
  padding: 12px 40px;
}

.devider {
  border-top: 1px solid #bbb;
  padding: 15px 0px;
}

.routePageTitleWrapper {
  margin-bottom: 20px;
  color: #555555;
}

.dropDownContainerDiv {
  width: 160px;
  margin-left: "10px";
}

.customerCountHeader {
  display: flex;
  justify-content: space-between;
}

.inputContainerDiv {
  display: flex;
  padding-top: 5px;
  /* gap: 5px; */
}

.breadCrumbsLink {
  font-family: HM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #555555;
  text-decoration: none;
}

.breadCrumbsLink:hover {
  color: #555555;
}

.customerCounterTitle {
  font-family: 'HM Sans';
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #333333 !important;
  padding-top: 5px;
}

.textArea {
  width: 70% !important;
  height: 40px !important;
  resize: none !important;
  border-radius: 0;
  border: 1px solid #555555;
  font-family: "HM Sans" !important;
  font-weight: 400;
  font-size: 12px;
  color: #555555 !important;
}

.buttonStyle {
  width: 20%;
  height: 40px;
  margin-left: 20px;
  cursor: pointer;
  line-height: .560;
}

.statusModal {
  width: 647px !important;
  height: 382px !important;
  border-radius: 12px;
  border: 2px 0px 0px 0px;
  background-color: white;
  /* border: 2px solid #ffffff; */
  /* top: 20vh !important; */
  color: black !important;
  overflow: scroll;
}

.statusDataModal {
  width: 647px !important
}

.modalTitle {
  font-size: 22px !important;
  font-weight: 700 !important;
  color: #000000 !important;
  padding-top: 32px;
}

.tableContainer {
  width: 100% !important;
  overflow-x: scroll !important;
}

.transactionInModal {
  height: 31px;
  border: 1px solid #555555;
  display: flex !important;
  align-items: center;
  padding: 0px 12px;
  font-size: 14px;
  color: #000000;
}

.transactionDiv {
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 15px
}

.transactionTime {
  margin-left: 5px;
  font-weight: 700
}

.datePickerContainerDiv {
  width: 160px;
}

.inputDivider {
  width: 1px;
  height: 97%;
  background-color: rgb(34, 34, 34);
  margin-left: 27px;
  margin-right: 18px;
  margin-top: auto;
}

.dateAndCountryDiv {
  display: flex;
}

.storeAndExportBtn {
  display: flex;
  position: relative
}

.countryDropDown {
  margin-left: 3px;
  margin-right: 10px;
}

.sendMsgToStorePopover {
  width: 172px;
  border: 1px solid #000000;
  background-color: white;
  font-family: HM Sans;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-left: 8px;
  border-radius: 4px;
}

.sendMsgBtn:hover {
  background: #555555 !important;
  color: #fff !important;
  opacity: 1;
}

.ant-popover-inner {
  border-radius: 4px;
  border: 1px solid black;
}

.sendMsgHeading {
  display: flex;
  font-family: HM Sans;
  /* font-size: 18px; */
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #000000;
  padding-bottom: 10px;
  border-bottom: 1px solid #000000;
  margin-bottom: 15px;
}

.emailLogo {
  background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -1220px -139px;
  width: 20px;
  height: 16px;
  display: block;
  /* margin-top: 2px;
  margin-left: 0px;*/
  margin: auto;
  margin-right: 40px !important;
  cursor: pointer;
}

.closeSendMsgDialog {
  position: absolute;
  right: 7px;
  top: 5px;
  cursor: pointer
}

.sendMsgBtn {
  background-color: #222222;
  border-radius: 4px;
  border: 1px solid #222222;
  padding: 4px;
  color: white;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sendMsgToStoreContainer {
  width: 267px;
  position: absolute;
  right: 0px;
  top: 39px;
  z-index: 10;
  background-color: white;
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 4px;
  overflow: hidden;
}

.closeIcon {
  background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -1054px -138px;
  width: 22px;
  height: 22px;
  /* display: block; */
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
}

.sendMsgStores {
  width: 245px;
  margin-bottom: 10px;
}

.messageTxtArea {
  width: 100%;
  padding: 6px 8px;
}

.iconbutton {
  position: absolute;
  right: 21%;
  top: -10px;
  color: #3f3c3c;
  background: none !important;
  border: none !important;
  cursor: pointer;
  font-size: 20px;
  /* Adjust the size as needed */
  padding-left: 10px;
  margin-right: 18px;
}

.editMsgToStoreContainer {
  width: 267px;
  z-index: 1000;
  background-color: white;
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 4px;
  overflow: hidden;
}

.clearEditMsg {
  position: absolute;
  right: 0;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.customOkBtnStyle {
  background-color: black;
}

.customOkBtnStyle:hover {
  background-color: #555555 !important;
  opacity: 1;
}

.customCancelBtnStyle {
  background-color: white;
  color: black;
  border: 1px solid black;
}

.customCancelBtnStyle:hover {
  border: 1px solid black !important;
  color: black !important;
  opacity: 1;
}

.messageLengthDiv {
  text-align: right;
  font-size: 12px;
  margin: -5px 2px;
}


@media (max-width: 480px) {
  .customerCountHeader {
    display: block !important;
    margin-bottom: 30px;
  }

  .inputContainerDiv {
    display: block;
    padding-top: 0px;
  }

  .mainDiv {
    padding-bottom: 50px !important;
  }

  .datePickerContainerDiv {
    margin-bottom: 10px;
    width: 100%
  }

  .dropDownContainerDiv {
    width: 100% !important
  }

  .dateAndCountryDiv {
    display: block;
  }

  .storeAndExportBtn {
    display: block !important;
  }

  .tableContainer {
    width: 100%;
    overflow-x: scroll;
  }

  .textArea {
    width: 238px !important;
    height: 40px !important;
    resize: none !important;
    border-radius: 0;
    border: 1px solid #555555;
    font-family: "HM Sans" !important;
    font-weight: 400;
    font-size: 12px;
    color: #555555 !important;
  }

  .buttonStyle {
    width: 20%;
    height: 40px;
    margin-left: 20px;
    cursor: pointer;
    line-height: .560;
  }

  .customerCounterTitle {
    margin-bottom: 10px;
  }

  /* .transactionDiv {
    gap: 5px;
  } */

  .countryDropDown {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px !important;
  }

  .inputDivider {
    display: none;
  }

  .exportButtonDiv {
    /* width: 100%; */
    margin-left: 0px !important;
    margin-top: 10px
  }

  .sendMsgToStorePopover {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
    height: 31px;
  }

  .sendMsgToStoreContainer {
    width: 100%;
    top: 121px;
  }

  .sendMsgStores {
    width: 100%;
  }

  .messageTxtArea {
    margin-left: 1px;
  }

  .serviceMessageColumn {
    width: 150px;
  }
}

@media(max-width:355px) {
  .transactionDiv {
    display: block !important;
  }

  .transactionDiv>div:first-child {
    margin-bottom: 5px !important;
  }

  .transactionInModal {
    padding: 0px 12px !important;
  }

}

@media(max-width:420px) {
  .transactionInModal {
    font-size: 12px;
    padding: 0px 3px;
  }

  .transactionTime {
    margin: 0px;
  }
}

@media (min-width: 481px) and (max-width: 854px) {
  .customerCountHeader {
    display: block !important;
    margin-bottom: 30px;
  }

  .storeDropdown {
    display: block;
  }

  .inputDivider {
    display: none;
  }

  .inputContainerDiv {
    display: block;
    padding-top: 0px;
  }

  .dateAndCountryDiv {
    /* gap: 10px; */
    /* justify-content: space-between; */
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .storeAndExportBtn {
    /* justify-content: space-between; */
    padding-right: 10px;
  }

  .dropDownContainerDiv {
    width: 151px
  }

  .exportButtonDiv {
    margin-left: 13px !important;
    width: 150px;
  }

  .sendMsgToStorePopover {
    width: 37%;
    height: 33px;
    font-size: 11px;
  }

  .serviceMessageColumn {
    width: 130px;
  }
}

@media (min-width: 855px) and (max-width: 1129px) {
  .customerCountHeader {
    display: block !important;
    margin-bottom: 30px;
  }

  .tableContainer {
    width: 100%;
    overflow-x: scroll;
  }

  .inputDivider {
    display: contents !important;
    width: 1px;
    height: 97%;
    background-color: rgb(34, 34, 34);
    margin-left: 27px;
    margin-right: 18px;
    margin-top: auto;
  }
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  /* Ensure the spinner container takes the full height */
}

@media (max-width: 1100px) {
  .textArea {
    width: 238px !important;
    height: 40px !important;
    resize: none !important;
    border-radius: 0;
    border: 1px solid #555555;
    font-family: "HM Sans" !important;
    font-weight: 400;
    font-size: 12px;
    color: #555555 !important;
  }

  .buttonStyle {
    width: 20%;
    height: 40px;
    margin-left: 20px;
    cursor: pointer;
    line-height: .560;
  }
}