.mainDiv {
  height: 100vh;
  color: blue;
  padding: 12px 40px;
}

.routePageTitleWrapper {
  margin-bottom: 20px;
  color: #555555;
}

.breadCrumbsLink {
  font-family: HM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #555555;
  text-decoration: none;
}

.posHeader {
  display: flex;
  justify-content: space-between;
}

.posTitle {
  font-family: 'HM Sans';
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #333333 !important;
  padding-top: 5px;
}

.inputContainerDiv {
  display: flex;
  padding-top: 5px;
  /* gap: 5px; */
}

.dateAndCountryDiv {
  display: flex;
}

.datePickerContainerDiv {
  width: 160px;
}

.dropDownContainerDiv {
  width: 160px;
  margin-left: "10px";
}

.countryDropDown {
  margin-left: 3px;
  margin-right: 10px;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  /* Ensure the spinner container takes the full height */
}

.breadCrumbsLink:hover {
  color: #555555;
}

.filterIcon {
  background: url("../../assets/images/svgs/sprite-icons-desktop.svg") no-repeat -687px -217px;
  width: 57px;
  height: 25px;
  display: block;
}

.eyeIcon {
  background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -1269px -138px;
  width: 25px;
  height: 20px;
  display: block;
  margin-top: 2px;
  margin-left: 10px;
  cursor: pointer;
}

@media (max-width: 480px) {
  .posHeader {
    display: block !important;
    margin-bottom: 30px;
  }

  .dropDownContainerDiv {
    width: 100% !important
  }

  .countryDropDown {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px !important;
  }

  .inputContainerDiv {
    display: block;
  }


  .filterIcon {
    background: url("../../assets/images/svgs/sprite-icons-desktop.svg") no-repeat -687px -217px;
    width: 57px;
    height: 25px;
    display: block;
  }
}