.cardSectionBody {
    flex:1 0 calc(33.333%-16px);
    width: auto;
    border: 1px solid #CCCCCC;
    transition: border 0.5s ease;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
    overflow: hidden;
    background-image: linear-gradient(#F4F3F3, #FFFFFF, #FFFFFF);
    
}

.disabledCard{
    width: auto;
    /* background-color: #00000014; */
    pointer-events: none;
    opacity: 0.4;
    background-image: linear-gradient(#F4F3F3, #FFFFFF, #FFFFFF);
    border: 1px solid #CCCCCC
}
.cardSectionBody:hover {
    border-color: #201e1e;
}

.cardName {
    font-family: 'HM Sans';
    font-size: 13px;
    font-weight: 700;
    line-height: 18.89px;
    width: 80%;
    /* width: 94px; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5px;
}

.waringIconCountWrapper {
    width: 20%;
    display: grid;
    justify-content: space-around;
    border: 0.5px solid #CCCCCC;
    padding: 8px;
    border-radius: 5px;
    min-width: 80px
}

.devider {
    border-bottom: 0.5px solid #CCCCCC;
}

.warningIconWrapper {
    font-size: 16px;
    font-weight: bold;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.redWarningIcon {
    color: #C80F56;
    width: 30px;
    height: 30px;
}

.greenWarningIcon {
    color: #1E950B;
    width: 30px;
    height: 30px;
}

.displayCount {
    font-size: 40px;
    font-weight: bold;
    color: #C80F56;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}