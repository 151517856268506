.mainDiv {
  height: 100vh;
  color: blue;
  padding: 12px 40px;
}

.devider {
  border-top: 1px solid #bbb;
  padding: 15px 0px;
}

.routePageTitleWrapper {
  margin-bottom: 20px;
  color: #555555;
}

.pageTitle {
  font-family: HM Sans;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #333333;
}

.reportCardWrapper {
  display: flex;
  justify-content: flex-start;
  margin: 5px;
}
.alignViewDetailsBtn{
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-bottom: 18px;
  margin-top: 20px;
}

.breadCrumbsLink{
  font-family: HM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #555555;
  text-decoration: none;
  
}

.breadCrumbsLink:hover{
  color: #555555;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  /* Ensure the spinner container takes the full height */
}