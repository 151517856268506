.fallbackCompContainer{
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorText{
    font-size: 14px;
}

.tryAgainBtn{
    padding: 3px 8px;
    border: 1px solid gray;
    border-radius: 4px;
    background-color: white;
}