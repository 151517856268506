body {
  font-family: "HM Sans", sans-serif !important;
  /* Replace 'Your Default Font' with the actual font you want to use */
}

.defaultFontFamily,
textarea {
  font-family: "HM Sans", sans-serif !important;
}

.ApplicationCard {
  .ant-card-body {
    display: flex !important;
    align-items: center !important;
  }
}

.answerText th,
.answerText td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

// Here you can add other styles
.background {
  width: 100%;
  // background-image: url("../images/background.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.wrapper {
  // background-image: url('../assets/images/homebg.png');
  // background-repeat: no-repeat;
  // background-position: center center;
  // background-size: cover;
  // background: radial-gradient(
  //     70.83% 85.87% at 34.46% 29.17%,
  //     #435268 0%,
  //     #1d3551 36.98%,
  //     #0d2337 66.84%,
  //     #1d3551 100%
  //   ),
  //   #ffffff;
  // background: #e4e4e4;
}

.login-card {
  background-color: transparent;
  color: #cc071e !important;
  border: none !important;
}

.btn-login-button {
  border-radius: 0;
  border: none;
  background: #000;
  color: #ffffff;

  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    background: #000;
  }
}

.bg-login-cardgroup {
  border: 6px solid;
  border-image: linear-gradient(to right,
      #d6c6f6,
      #f6d2ef,
      #fcc5da,
      #fcdcd2,
      #ffe5ca) 1;
  border-image-slice: 1;
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.bg-login-cardgroup:hover {
  transform: perspective(1000px) rotateY(0deg);
}

.bg-login-cardgroup {
  // background-color: #f5f5f5 !important;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow effect */
  // border-radius: 8px; /* Rounded corners */
  /* Additional card styling properties */
}

.bg-login {
  border: none !important;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.28);
  backdrop-filter: blur(10px);
}

.header {
  // background-color: transparent;
  background-color: black;
  border: none !important;
}

.sidebar {
  background-color: #0d1927 !important;
  border: none !important;
}

.breadcrumb-item::before {
  content: "" !important;
}

.footer {
  background-color: #2c2c2c !important;
  color: #ffffff !important;
  height: 3.01rem !important;
}

.app-logo {
  width: 200px;
  padding: 20px;
}

.breadcrumb-item.active {
  font-size: 24px;
  color: #ffffff;
}

.logout-icon {
  transform: rotate(180deg);
  cursor: pointer;
}

.colvaluewidth {
  width: 26.2%;
}

.bg-row-transparent {
  background-color: #ffffff;
  color: #2c2c2c;
  border: 1px solid #cccaca;
  text-align: center;
}

.round-circle {
  display: inline-block;
  margin-bottom: -12px;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5px;
  margin-left: 10px;
}

.tile-element {
  box-sizing: border-box;
  background: #ffffff;
  border: 2px solid #fb7358;
  border-radius: 6px;
  border-left: 15px solid #fb7358;

  &.yellow {
    border: 2px solid #febe00;
    border-left: 15px solid #febe00;
  }

  &.green {
    border: 2px solid #1eb488;
    border-left: 15px solid #1eb488;
  }
}

.guage-alignment {
  padding-inline-start: 5px;
}

.customCard {
  .cardCount {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 70px;
    text-align: center;
    color: #555555;
  }

  .cardTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 54px;
    text-align: center;
    color: #929292;
  }

  .cardPercentage {
    font-size: 13px;

    .percentageIcon {
      width: 24px;
      height: 18.17px;
      display: inline-block;
      vertical-align: sub;

      &.upIcon {
        background: url(../images/up-icon.png);
      }

      &.downIcon {
        background: url(../images/down-icon.png);
      }
    }

    .percentageText {
      color: #555555;

      &.upIconText {
        color: #579c21;
      }

      &.downIconText {
        color: #e44659;
      }
    }
  }

  .cardIcon {
    width: 60px;
    height: 70.02px;
    float: right;

    img {
      width: 50px;
    }
  }
}

.leaflet-container {
  width: 100% !important;
}

.leaflet-bottom {
  display: none !important;
}

/* For WebKit-based browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px;
  /* width of the scrollbar */
  height: 10px;
}

.main-98 {
  width: 93% !important;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-primary-100, #222);
  border-radius: 5px;
  /* roundness of the scrollbar thumb */
}

// ::-webkit-scrollbar-thumb:hover {
//   background: linear-gradient(
//     180deg,
//     #79abc2 0%,
//     #5788ac 49.48%,
//     #5070a4 98.44%
//   ); /* color of the scrollbar thumb on hover */
// }

/* For Firefox */
/* Note: Firefox currently does not support styling the scrollbar track */
/* However, you can still style the scrollbar thumb */
::-moz-scrollbar-thumb {
  // background: linear-gradient(
  //   180deg,
  //   #79abc2 0%,
  //   #5788ac 49.48%,
  //   #5070a4 98.44%
  // ); /* color of the scrollbar thumb */
  border-radius: 5px;
  /* roundness of the scrollbar thumb */
  background-color: var(--primary-primary-100, #222);
}

// ::-moz-scrollbar-thumb:hover {
//   background: linear-gradient(
//     180deg,
//     #79abc2 0%,
//     #5788ac 49.48%,
//     #5070a4 98.44%
//   ); /* color of the scrollbar thumb on hover */
// }

.HistoryIcon {
  background-position: -182px -49px !important;
  width: 15px;
  height: 15px;
  margin: 4px;
}

.VideosIcon {
  background-position: -230px -49px !important;
  width: 17px;
  height: 15px;
  margin: 4px;
}

.FilesIcon {
  background-position: -280px -49px !important;
  width: 12px;
  height: 15px;
  margin: 4px;
}

.LinksIcon {
  background-position: -334px -49px !important;
  width: 21px;
  height: 15px;
  margin: 4px;
}

.InlineDisplayProperty {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.activeRecommendBtn {
  background-color: #dae7e2 !important;
  color: #3a3a3a !important;
  border: 2px solid #cce0d9 !important;
  border-radius: 38px !important;
  box-shadow: none !important;
  margin-right: 12px;
  padding: 3px 12px;
}

.recommendBtn,
.recommendBtn:focus-visible,
.recommendBtn:active {
  background: #ffffff !important;
  color: #3a3a3a !important;
  border: 2px solid #cce0d9 !important;
  border-radius: 38px !important;
  box-shadow: none !important;
  margin-right: 8px;
  padding: 3px 12px;
}

.recommendBtn:hover {
  background: rgb(243, 242, 241) !important;
}

.videoModal .modal-content {
  overflow: initial !important;
  border: none !important;
  margin: 0px 25px;
}

.typingAnime {
  width: 70px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 15px 0px;
}

.typingAnime>div {
  width: 14px;
  height: 14px;
  background-color: #cce0d9;
  margin: 0 2px 0 2px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.typingAnime .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.typingAnime .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.wordIcon {
  background-position: -38px -96px !important;
  width: 15px;
  height: 15px;
  margin-right: 8px;
}

.pdfIcon {
  background-position: -85px -96px !important;
  width: 15px;
  height: 15px;
  margin-right: 8px;
}

.excelIcon {
  background-position: -134px -96px !important;
  width: 15px;
  height: 15px;
  margin-right: 8px;
}

.pptIcon {
  background-position: -182px -96px !important;
  width: 15px;
  height: 15px;
  margin-right: 8px;
}

.spriteUrl {
  background: url(../images/svgs/ai-companion-sprite.svg) no-repeat;
}

.Answer_answerText__fK2eo p {
  margin-bottom: 0;
}

.answerText p {
  margin-bottom: 0px;
}

.videoModal {
  .modal.show .modal-dialog {
    background-color: #ffffff;
    margin: 0px;
  }

  .modal-content {
    border: none;
  }

  .modal-dialog.modal-dialog-centered {
    width: 100%;
    height: 100%;
  }
}

.chatPopover {
  .popover-arrow {
    display: none;
  }

  .popover-body {
    padding: 8px;
  }
}

.popover.bs-popover-top.fade.show.undefined.chatPopover {
  left: -2% !important;
}

.DownloadIcon {
  background-position: -38px -139px !important;
  width: 15px;
  height: 13px;
  display: inline-flex;
  margin: 4px;
  cursor: pointer;
  padding: 0px 12px !important;
}

.chatPopoverRow {
  border-bottom: 1px solid #c6ddd4;
}

.alignProp {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.sampleQueTT {
  .tooltip-arrow {
    transform: (64px, 29px);
  }
}

.sampleQuePopover.popover {
  max-width: 400px;
  min-width: 380px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
}

.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  min-width: 160px;
  z-index: 1;
}

.custom-spin .ant-spin-dot {
  color: black; /* Change to your desired color */
}

.custom-modal .ant-modal-close-x {
  color: white; /* Change to your desired color */
}

// 100%
@media screen and (min-width: 1264px) and (max-width: 1400px) {

  .dropdown-content.Store,
  .dropdown-content.HAI,
  .dropdown-content.Business,
  .dropdown-content.Operational {
    top: -45% !important;
    left: 16% !important;
    max-width: 350px;
  }
}

// 67
@media screen and (min-width: 2013px) and (max-width: 3100px) {

  .dropdown-content.Store,
  .dropdown-content.HAI,
  .dropdown-content.Business,
  .dropdown-content.Operational {
    top: -48% !important;
    left: 23% !important;
    max-width: 400px;
  }
}

// 90 %
@media screen and (min-width: 1401px) and (max-width: 1599px) {

  .dropdown-content.Store,
  .dropdown-content.HAI,
  .dropdown-content.Business,
  .dropdown-content.Operational {
    top: -45% !important;
    left: 18% !important;
    max-width: 350px;
  }
}

// 80%
@media screen and (min-width: 1600px) and (max-width: 1700px) {

  .dropdown-content.Store,
  .dropdown-content.HAI,
  .dropdown-content.Business,
  .dropdown-content.Operational {
    top: -45% !important;
    left: 18% !important;
    max-width: 400px;
  }
}

// 75%
@media screen and (min-width: 1751px) and (max-width: 1820px) {

  .dropdown-content.Store,
  .dropdown-content.HAI,
  .dropdown-content.Business,
  .dropdown-content.Operational {
    top: -45% !important;
    left: 23% !important;
    max-width: 400px;
  }
}

.dropdown-content.HAI {
  top: -39%;
  left: 20%;
}

.dropdown-content.Business {
  top: -39%;
  left: 13%;
}

.dropdown-content.Store {
  top: -39%;
  left: 12%;
}

.dropdown-content.Operational {
  top: -39%;
  left: 18%;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #e6e6e6;
}

.mob-MoreInfo {
  float: right;
  position: absolute;
  top: 0;
  right: 0;
  color: #000;

  .dd-menu {
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.7);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    max-width: 90vw;
    min-width: 80vw;
    overflow: hidden;
  }

  .sysSampleQue {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 10px;
    cursor: pointer;
  }

  .sysSampleQue:hover {
    background-color: #fff;
    color: #000;
  }

  .lineSeperator {
    border-bottom: 1px solid #d1d1d1;
  }

  .dropdown-toggle {
    background: transparent;
    border: none;
    height: 20px;

    &::after {
      display: none;
    }
  }

  .dropdown-item {
    text-wrap: wrap;

    &:hover {
      background: #fff;
      color: #000;
    }

    &:focus,
    &:active,
    &:focus-visible {
      background: #ebebeb;
      color: black;
    }
  }

  .mob-MoreInfo-icon {
    background: url("../images/svgs/aicompanion-mobile-sprite.svg") no-repeat -354px -60px;
    width: 4px;
    height: 16px;
    display: block;
  }
}

.citationPanelContent p {
  overflow: scroll;
}

.activeSystem {
  box-shadow: 0px 0px 12px 0px #4f7d6c !important;
}

.moreInfo-ChatWindow {
  .dropdown-menu {
    min-width: 40vw;
  }

  .dropdown-toggle {
    background: transparent;
    border: none;
    height: auto;
    padding: 2px;

    &::after {
      display: none;
    }

    &:hover,
    &:focus,
    &:active,
    &:focus-visible {
      background: transparent;
      color: black;
    }
  }

  .dropdown-item {
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background: #fff;
      color: #000;
    }

    &:focus,
    &:active,
    &:focus-visible {
      background: #ebebeb;
      color: black;
    }
  }
}

.GramerlyPopDiv {
  .popover-body {
    background: #000000b8;
    box-shadow: 0px 0px 6px 0px #00000040;
    color: #ffffff;
  }
}

.rotate-90 {
  transform: rotate(90deg);
}

.moreInfo-ChatWindow a {
  padding: 4px;
}

.grammerlycheckbox {
  .form-check-input:checked {
    background-color: #000 !important;
    border-color: #fff !important;
  }

  .form-check-input:focus {
    box-shadow: none !important;
  }
}

.ant-dropdown-menu-title-content {
  color: #fff;
}

.belowIcon {
  background-position: -387px -133px !important;
  width: 18px;
  height: 18px;
  display: inline-flex;
  margin-left: 8px;
}

.ant-dropdown-menu {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.ant-dropdown-arrow::after {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.stockmanagement p {
  width: 93px;
  height: 34px;
  // position: absolute;
  // left: 25%;
  // top: 18%;
}

.stockmanagement span {
  position: absolute;
  top: 2%;
}

// .stockmanagement div {
//   position: absolute;
//   bottom: 3%;
//   right: 37%;
// }

.disabled {
  color: gray;
  cursor: not-allowed;
  opacity: 0.5;
}

.stockmanagement {
  top: 22%;
  left: 64%;
  font-size: 11px;
  font-weight: 700;
  line-height: 17.44px;
  text-align: center;
  color: black;

  // transform: rotate(33deg);
  // background: linear-gradient(180deg, #b8b8b8 0%, #ffffff 48.96%, #9a9a9a 100%);
}

.reports p {
  width: 93px;
  height: 8px;
}

.reports span {
  position: absolute;
  top: 5%;
  left: 30%;
}

.reports {
  top: 6%;
  left: 32%;
  font-size: 11px;
  font-weight: 700;
  line-height: 17.44px;
  text-align: center;
  color: black;

  // transform: rotate(33deg);
  // background: linear-gradient(180deg, #b8b8b8 0%, #ffffff 48.96%, #9a9a9a 100%);
}

.hardware p {
  width: 93px;
  height: 8px;
}

.hardware span {
  position: absolute;
  left: 22%;
  top: 25%;
}

.hardware {
  top: 24%;
  left: -1%;
  font-size: 11px;
  font-weight: 700;
  line-height: 17.44px;
  text-align: center;
  color: black;
}

.salesmanagement p {
  width: 93px;
  height: 24px;
}

.salesmanagement span {
  position: absolute;
  bottom: 3%;
  left: 34%;
}

.salesmanagement {
  top: 55%;
  left: 2%;
  font-size: 11px;
  font-weight: 700;
  line-height: 17.44px;
  text-align: center;
  color: black;
}

.apps p {
  width: 93px;
  height: 12px;
}

.apps span {
  position: absolute;
  bottom: 13%;
  right: 31%;
}

.apps {
  top: 76%;
  left: 32%;
  font-size: 11px;
  font-weight: 700;
  line-height: 17.44px;
  text-align: center;
  color: black;
}

.network p {
  width: 93px;
  height: 7px;
}

.network span {
  position: absolute;
  top: 16%;
  right: 26%;
}

.network {
  top: 59%;
  left: 63%;
  font-size: 11px;
  font-weight: 700;
  line-height: 17.44px;
  text-align: center;
  color: black;
}

.ant-breadcrumb,
.ant-breadcrumb-link {
  color: #555555 !important;
  font-family: HM Sans !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.custom-checkbox-addApp .ant-checkbox-inner {
  background-color: white !important;
  border-color: #555555 !important;
}

.custom-checkbox-addApp .ant-checkbox-checked .ant-checkbox-inner {
  background-color: white !important;
  border-color: #555555 !important;
}

.custom-checkbox-addApp .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #555555 !important;
}

.custom-checkbox-addApp .ant-checkbox-inner {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  border-width: 2px;
}

/* styles.css */
.custom-checkbox .ant-checkbox-inner {
  background-color: white !important;
  border-color: #555555 !important;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: white !important;
  border-color: #555555 !important;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #555555 !important;
}

/* styles.css */
.custom-checkbox-group .ant-checkbox-inner {
  background-color: white !important;
  border-color: #555555 !important;
}

.custom-checkbox-group .ant-checkbox-checked .ant-checkbox-inner {
  background-color: white !important;
  border-color: #555555 !important;
}

.custom-checkbox-group .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #555555 !important;
}

.custom-checkbox .ant-checkbox-inner,
.custom-checkbox-group .ant-checkbox-inner {
  width: 19px;
  height: 19px;
  border-radius: 2px;
  border-width: 3px;
}

.custom-checkbox .ant-checkbox-inner::after,
.custom-checkbox-group .ant-checkbox-inner::after {
  width: 6px;
  height: 11px;
  border-width: 3px;
}

.custom-checkbox .ant-checkbox-indeterminate .ant-checkbox-inner::after,
.custom-checkbox-group .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  width: 11px !important;
  height: 11px !important;
  background-color: #555555 !important;
}

.custom-checkbox .ant-checkbox {
  // transform: scale(1.5); /* Scale up the checkbox */
}

.custom-checkbox-group {
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* Adjust the gap as needed for spacing between checkboxes */
}

.custom-checkbox {
  display: flex;
  align-items: center;
}

// .ant-select-item-option-content{
//   display: flex;
// }

.custom-tooltip .ant-tooltip-arrow::before {
  width: 0;
  height: 0;
  border-left: 6px solid transparent !important;
  border-right: 6px solid transparent !important;
  border-top: 6px solid gray !important;
  /* Outer border color */
  position: absolute;
  bottom: -7px !important;
  /* Adjust based on your tooltip's position */
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: -6px;
  /* Adjust margin */
}

.custom-tooltip .ant-tooltip-arrow {
  content: "";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white !important;
  /* Inner arrow color */
  position: absolute;
  bottom: -6px;
  /* Adjust based on your tooltip's position */
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: -5px;
  /* Adjust margin */
  filter: drop-shadow(0 1px 1px black);
  /* Add shadow */
}

.custom-tooltip .ant-tooltip-inner {
  background-color: white !important;
  color: gray;
  border: 1px solid gray;
  width: 200px;
  /* Set fixed width */
  word-wrap: break-word;
  /* Ensure text wraps within the container */
  text-align: center;
}

/* Custom Select styling */
.custom-select .ant-select-arrow {
  display: flex !important;
  align-items: center !important;
  color: rgb(156, 156, 156) !important;
  // border-left: 1px solid black !important;
  //   padding-left: 6px !important;
  //   height: 14px !important;
}

.custom-select .ant-select-arrow::before {
  content: "" !important;
  display: inline-block !important;
  width: 1px !important;
  height: 15px !important;
  background-color: rgb(156, 156, 156) !important;
  margin-right: 8px !important;
}

.custom-checkbox-group .ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #555555 !important;
}

.ant-table-wrapper .ant-table-cell-scrollbar {
  box-shadow: none !important;
}

.ant-input:hover{
  border-color: black !important;
  box-shadow: none !important;
}

.ant-input:focus{
  border-color: black !important;
  box-shadow: none !important;
}

.store-360-modal {
  max-width: 1300px !important;
  margin:auto;
}

// .store-360-modal .ant-modal-content{
//   max-width: 1300px;
// }

.ant-picker-outlined:focus-within{
  border-color: black !important;
  box-shadow: none !important;
}

@media (max-width: 768px) {
  .custom-checkbox-group {
    width: 100%;
    gap: 8px;
    /* Smaller gap between checkboxes on mobile */
  }

  .custom-checkbox-group .ant-checkbox-wrapper {
    width: 100%;
  }

  .ant-checkbox+span {
    white-space: nowrap;
    padding: 0 5px;
  }
}

.ant-checkbox+span {
  white-space: nowrap;
  padding: 0 5px;
}

.ant-select-selector,
.ant-picker-outlined {
  border: 1px solid #979797 !important;
  border-radius: 3px !important;
}

.ant-select-selection-placeholder,
.ant-picker-input>input::placeholder {
  color: #6f6f6f !important;
}

.ant-checkbox-group {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.orange {
  top: 37%;
  left: -37px;
  transform: rotate(90deg);
  background: linear-gradient(180deg, #b8b8b8 0%, #ffffff 48.96%, #9a9a9a 100%);
}

.yellow {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(180deg, #b8b8b8 0%, #ffffff 48.96%, #9a9a9a 100%);
}

.green {
  top: 36%;
  right: -37px;
  transform: rotate(90deg);
  background: linear-gradient(180deg, #b8b8b8 0%, #ffffff 48.96%, #9a9a9a 100%);
}

.blue {
  top: 0;
  right: 50%;
  transform: translateX(50%);
  background: linear-gradient(180deg, #b8b8b8 0%, #ffffff 48.96%, #9a9a9a 100%);
}

.purple {
  bottom: 0;
  right: 50%;
  transform: translateX(50%);
  background: linear-gradient(180deg, #b8b8b8 0%, #ffffff 48.96%, #9a9a9a 100%);
}

.ant-modal-content {
  height: 88vh !important;
  background: none !important;
}

.ant-modal-header {
  background: none !important;
  color: #fff !important;
}

.ant-modal-title {
  color: #fff !important;
}

.ant-modal-footer {
  display: none !important;
}

.checkbox-title {
  width: 45.88px;
  height: 15.98px;
  gap: 0px;
  opacity: 0px;
}

.check-handm-icon {
  background: url("../images/svgs/sprite-iservice-desktop.svg") no-repeat -47px -217px;
  width: 25px;
  height: 25px;
  display: block;
  padding: 0px 5px;
}

.check-cos-icon {
  background: url("../images/svgs/sprite-iservice-desktop.svg") no-repeat -112px -217px;
  width: 46px;
  height: 25px;
  display: block;
}

.check-weekday-icon {
  background: url("../images/svgs/sprite-iservice-desktop.svg") no-repeat -199px -217px;
  width: 87px;
  height: 25px;
  display: block;
}

.check-monkl-icon {
  background: url("../images/svgs/sprite-iservice-desktop.svg") no-repeat -325px -217px;
  width: 91px;
  height: 25px;
  display: block;
}

.check-otherstories-icon {
  background: url("../images/svgs/sprite-iservice-desktop.svg") no-repeat -453px -217px;
  width: 114px;
  height: 25px;
  display: block;
}

.check-arket-icon {
  background: url("../images/svgs/sprite-iservice-desktop.svg") no-repeat -606px -217px;
  width: 79px;
  height: 25px;
  display: block;
}

.ant-modal-body {
  height: 78vh;
}

.ant-card-body {
  display: flex;
  justify-content: flex-end;
  padding: 5px !important;
}

.ant-collapse-header-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #1d1d1b;
}

.ant-table-thead>tr>th {
  background-color: #434343 !important;
  font-size: 13px !important;
  color: white !important;
  border-radius: 0px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ant-table-tbody>tr>td {
  font-size: 13px;
  color: #000000 !important;
  font-weight: 600;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

/* Target the table header cell */
.ant-table-column-title {
  display: flex !important;
  align-items: center !important;
}

.ant-table-wrapper .ant-table-column-sorters {
  display: inline-flex !important;
  flex: auto !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.ant-table-wrapper .ant-table-column-title {
  position: relative !important;
  z-index: 1 !important;
  flex: 1 !important;
  display: block !important;
}

.ant-table-tbody>tr>td>a {
  padding: 0px;
}

.ant-table-column-sorter-up {
  color: white;
}

.ant-table-column-sorter-down {
  color: white;
}

.ApplicationHealthHeader> {}

.DropDownList_inputWrapper_StoreNotification {
  width: 30% !important;
}

.DropDownList_inputSearch_StoreNotification {
  width: 100% !important;
}

.DropDownList_inputWrapper_customerCounterStoreToMsgStyle {
  width: 245px !important;
  height: 175px !important;
}

.DropDownList_inputSearch_customerCounterStoreToMsgStyle {
  width: 245px !important;
}

.DropDownList_ulStyle_customerCounterStoreToMsgStyle {
  height: 145px !important;
}

.DropDownList_ulStyle_customerCounterStoreToMsgStyle .ReactVirtualized__Grid .ReactVirtualized__Grid__innerScrollContainer {
  max-width: 245px !important;
}

@media (max-width: 766px) {
  .DropDownList_inputWrapper_StoreNotification {
    width: 85% !important;
  }
}

.submitNotificationBtn:hover {
  background: #555555 !important;
  color: #fff !important;
  opacity: 1;
}

.ant-picker-panel-container .ant-picker-panel .ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: black !important;
  border: 1px solid black !important;
}

.ant-picker-dropdown .ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid black !important;
}

.ant-picker-ok button {
  background-color: black !important;
  border: 1px solid black !important;
  color: white !important;
}

.ant-picker-now-btn {
  color: black !important;
}

.ant-picker-now:hover {
  color: black !important;
}

.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: black !important;
  border: 1px solid black !important;
  color: white !important;
}

.ant-table-header {
  border-radius: 0px !important;
}

.notSync-table .ant-pagination {
  flex-wrap: nowrap !important;
}

.anticon-filter {
  color: white;
}

.custom-filter .anticon-filter {
  color: black;
  cursor: pointer;
}

.ant-dropdown-menu-without-submenu {
  background-color: white !important;
}

.ant-btn-primary {
  background-color: black;
}

.ant-btn-primary:hover {
  background-color: black !important;
}

.ant-btn-link span {
  color: black !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: black !important;
  border-color: black !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  // background-color: black !important;
  border-color: black !important;
}

.custom-checkbox-addApp .ant-checkbox-checked .ant-checkbox-inner {
  background-color: white !important;
  border-color: #555555 !important;
  width: 16px !important;
  height: 16px !important;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  // position: absolute !important;
  // top: 50% !important;
  // inset-inline-end: 0 !important;
  // width: 1px !important;
  height: 0% !important;
  // background-color: #f0f0f0 !important;
  // transform: translateY(-50%) !important;
  // transition: background-color 0.2s !important;
  // content: "";
}

.ant-picker-header-view .ant-picker-year-btn:hover,
.ant-picker-header-view .ant-picker-month-btn:hover {
  color: #434343 !important; /* Replace with your desired color */
}

.ant-picker-decade-btn:hover {
  color: #434343 !important; /* Replace with your desired color */
}

/* Change color of the selected sort button */
.ant-table-column-sorters .ant-table-column-sorter-inner .ant-table-column-sorter-up.active,
.ant-table-column-sorters .ant-table-column-sorter-inner .ant-table-column-sorter-down.active {
  color: black !important; /* Replace with your desired color */
}

.ant-tooltip-inner {
  background-color: white !important; /* Keep the default or set your desired tooltip background color */
  color: black !important; /* Keep the default or set your desired tooltip text color */
  border: 1px solid black !important; /* Keep the default or set your desired tooltip border color */
}

/* Change tooltip arrow to white with black border */
.ant-tooltip-arrow {
  border-color: black !important; /* Set the border color to black */
}

.ant-tooltip-arrow::before {
  background-color: white !important; /* Set the arrow color to white */
  border-color: black !important; /* Set the border color to black */
}

@media (max-width: 450px) {
  .ant-picker-outlined {
    width: 100%;
  }

  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute !important;
    top: 50% !important;
    inset-inline-end: 0 !important;
    width: 1px !important;
    height: 0% !important;
    background-color: #f0f0f0 !important;
    transform: translateY(-50%) !important;
    transition: background-color 0.2s !important;
    content: "";
  }
}

@media (max-width: 480px) {
  .DropDownList_inputWrapper_StoreNotification {
    width: 81% !important;
  }

  .DropDownList_inputSearch_StoreNotification {
    width: 100% !important;
  }
}

.custom-confirm-modal .ant-modal-content {
  background-color: #ffffff !important;
  height: 26vh !important;
}

.update-modal .ant-modal-content {
  background-color: #ffffff !important;
  height: 60vh !important;
  overflow: scroll;
  scrollbar-width: none;
}

.update-modal .ant-modal-close {
  margin-top: 10px;
}

.ant-pagination {
  margin-right: 15px !important;
  overflow: hidden;
}

.ant-pagination-item-active {
  border-color: black !important;
}

@media (max-height: 818px) {
  .update-modal .ant-modal-content {
    // background-color: #ffffff !important;
    height: 75vh !important;
  }
}

.ant-popover-inner {
  padding: 0px !important;
}

@media (min-width: 576px) {
  .entranceInfo-modal .modal-dialog {
    max-width: none !important;
    margin-left: 30px;
    margin-right: 30px;
  }
}