.metricsListContainer {
    padding: 12px 40px;
  }

.dropDownContainerDiv{
    margin-left: "10px"
}

.metricsListHeader{
    display: flex;
    justify-content: space-between;
}
.inputContainerDiv{
    display:flex;
    padding-top:5px;
}

.datePickerContainerDiv{
    margin-bottom: 10px;
   }

.divider {
    border-top: 1px solid #bbb;
    padding: 15px 0px;
}

.routePageTitleWrapper {
    margin-bottom: 20px;
    color: #555555;
  }  

  .breadCrumbsLink {
    font-family: HM Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #555555;
    text-decoration: none;
  }

  .breadCrumbsLink:hover {
    color: #555555;
  }

  .headerTitle{
    font-family: 'HM Sans';
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 7px;
    color: #333333 !important;
    padding-top: 5px;
  }


@media(max-width:480px){
    .metricsListHeader{
        display: block !important;
        margin-bottom: 30px;
    }

    .inputContainerDiv{
        display:block;
        padding-top:0px;
        
    }
   
   .mainDiv{
    padding-bottom: 50px !important;
   }
   
   .datePickerContainerDiv{
    margin-bottom: 10px;
   }
}

@media(min-width:481px) and (max-width:610px){
    .metricsListHeader{
        display: block !important;
        margin-bottom: 30px;
    }

   
  }