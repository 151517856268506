.header{
    /* position: relative; */
    position: sticky;
    top: 0;
    z-index: 10;
    max-width: 100%;
    background: black;
    box-shadow: 0 2px 8px #f0f1f2;
    padding-left: 20px;
    height: 63px;
}
.headerTitleWrapper{
    display: flex;
    align-items: center;
    padding: 14px 4px;
}
.headerDivider {
    /* border-left: 1px solid #FFFFFF; */
    border-right: 1px solid #FFFFFF;
    height: 38px;
    margin: 0px 10px;
}
.headerFont{
    color: #f0f1f2;
    font-size: 22px;
    font-family: sans-serif;
    font-weight: 400;
}

.headerLogo{
    background: url("../../assets/images/svgs/sprite-iservice.svg") no-repeat -36px -85px;
    width: 140px;
    height: 26px;
    display: inline-flex;
    align-items: center;
}

@media(max-width:450px){
    .header{
        padding-left: 10px !important;
        display: flex !important;
        justify-content: center !important;
    }

    .headerTitleWrapper{
        padding: 12px 4px;
    }

    .headerLogo{
        width: 147px;
    }

    .headerFont{
        color: #f0f1f2;
        font-size: 18px;
        font-family: "HM Group Ampersand";
        font-weight: 400;
        width:166px
    }

    .header{
        height: 75px;
    }

}