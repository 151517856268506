.mainDiv {
  height: 100vh;
  color: blue;
  padding: 12px 40px;
}

.devider {
  border-top: 1px solid #bbb;
  padding: 15px 0px;
}

.routePageTitleWrapper {
  margin-bottom: 20px;
  color: #555555;
}

.dropDownContainerDiv {
  margin-left: 10px;
  width: 160px;
}

.customerCountHeader {
  display: flex;
  justify-content: space-between;
}

.inputContainerDiv {
  display: flex;
  padding-top: 5px;
}

.breadCrumbsLink {
  font-family: HM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #555555;
  text-decoration: none;
}

.breadCrumbsLink:hover {
  color: #555555;
}

.customerCounterTitle {
  font-family: 'HM Sans';
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #333333 !important;
  padding-top: 5px;
}

.textArea {
  width: 70% !important;
  height: 40px !important;
  resize: none !important;
  border-radius: 0;
  border: 1px solid #555555;
  font-family: "HM Sans" !important;
  font-weight: 400;
  font-size: 12px;
  color: #555555 !important;
}

.buttonStyle {
  width: 20%;
  height: 40px;
  margin-left: 20px;
  cursor: pointer;
  line-height: .560;
}

.statusModal {
  width: 647px !important;
  height: 382px !important;
  border-radius: 12px;
  border: 2px 0px 0px 0px;
  background-color: white;
  /* border: 2px solid #ffffff; */
  /* top: 20vh !important; */
  color: black !important;
  overflow: scroll;
}

.statusDataModal {
  width: 647px !important
}

.modalTitle {
  font-size: 22px !important;
  font-weight: 700 !important;
  color: #000000 !important
}

.tableContainer {
  width: 100% !important;
  overflow-x: scroll !important;
}

.greenIndicator {
  background: url("../../assets/images/svgs/sprite-iservice.svg") no-repeat -603px -94px;
  width: 11px;
  height: 11px;
  display: block;
}

.amberIndicator {
  background: url("../../assets/images/svgs/sprite-iservice.svg") no-repeat -554px -95px;
  width: 11px;
  height: 11px;
  display: block;
  fill: #4CAF50;
}

.eyeIcon {
  background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -1269px -138px;
  width: 25px;
  height: 20px;
  display: block;
  margin-top: 2px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.redIndicator {
  width: 11px;
  height: 11px;
  background-color: #C80F56;
  border-radius: 50%;
  display: inline-block;
}

.disconnectedDeviceWrapper {
  display: flex;
  width: 100%;
}

.disconnectedDevice {
  width: 60%;
}

.faliledNetworkChart {
  width: 40%;
  height: 395px;
  max-height: 600px;
  margin-left: 10px;
  background: black;
}

.exportButtonDiv {
  margin-left: 10px;
  width: 110px;
}

.inputDivider {
  width: 1px;
  height: 97%;
  background-color: rgb(34, 34, 34);
  margin-left: 27px;
  margin-right: 18px;
  margin-top: auto;
}

.assetInsightBtn {
  margin-left: 8px;
  height: 30px;
  padding-left: 10px;
  background: white;
  padding-right: 10px;
}

.notConnectedDevicesMain {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}

@media (max-width: 480px) {
  .customerCountHeader {
    display: block !important;
    margin-bottom: 30px;
  }

  .inputContainerDiv {
    display: block;
    padding-top: 0px;
  }

  .mainDiv {
    padding-bottom: 50px !important;
  }

  .datePickerContainerDiv {
    margin-bottom: 10px;
  }

  .tableContainer {
    width: 100%;
    overflow-x: scroll;
  }

  .textArea {
    width: 100% !important;
    height: 40px !important;
    resize: none !important;
    border-radius: 0;
    border: 1px solid #555555;
    font-family: "HM Sans" !important;
    font-weight: 400;
    font-size: 12px;
    color: #555555 !important;
  }

  .buttonStyle {
    width: 70%;
    height: 40px;
    margin-left: 20px;
    cursor: pointer;
    line-height: .560;
  }

  .disconnectedDeviceWrapper {
    display: flex;
    flex-direction: column;
  }

  .disconnectedDevice {
    width: 100%;
  }

  .faliledNetworkChart {
    width: 100%;
    height: 350px;
    margin-left: 10px;
    background: black;
  }

  .dropDownContainerDiv {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 10px;
  }

  .exportButtonDiv {
    width: 100%;
    margin-left: 0;
  }

  .assetInsightBtn {
    width: 100%;
    margin-left: 0;
  }

  .notConnectedDevicesMain{
    display: block;
    margin-top: 10px;
  }

  .faliledNetworkChart{
    margin-top: 10px;
    margin-left: 0;
  }
}

@media (min-width: 481px) and (max-width: 700px) {
  .customerCountHeader {
    display: block;
    margin-bottom: 30px;
  }

  .tableContainer {
    width: 100%;
    overflow-x: scroll;
  }
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  /* Ensure the spinner container takes the full height */
}

@media (min-width: 700px) and (max-width: 900px) {
  .customerCountHeader {
    display: block ;
    margin-bottom: 30px;
  }
}
  
/* @media (max-width: 900px)  {
  .customerCountHeader {
    display: block ;
    margin-bottom: 30px;
  } 
}*/.mainDiv {
  height: 100vh;
  color: blue;
  padding: 12px 40px;
}

.devider {
  border-top: 1px solid #bbb;
  padding: 15px 0px;
}

.routePageTitleWrapper {
  margin-bottom: 20px;
  color: #555555;
}

.dropDownContainerDiv {
  margin-left: 10px;
  width: 160px;
}

.customerCountHeader {
  display: flex;
  justify-content: space-between;
}

.inputContainerDiv {
  display: flex;
  padding-top: 5px;
}

.breadCrumbsLink {
  font-family: HM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #555555;
  text-decoration: none;
}

.breadCrumbsLink:hover {
  color: #555555;
}

.customerCounterTitle {
  font-family: 'HM Sans';
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #333333 !important;
  padding-top: 5px;
}

.textArea {
  width: 70% !important;
  height: 40px !important;
  resize: none !important;
  border-radius: 0;
  border: 1px solid #555555;
  font-family: "HM Sans" !important;
  font-weight: 400;
  font-size: 12px;
  color: #555555 !important;
}

.buttonStyle {
  width: 20%;
  height: 40px;
  margin-left: 20px;
  cursor: pointer;
  line-height: .560;
}

.statusModal {
  width: 647px !important;
  height: 382px !important;
  border-radius: 12px;
  border: 2px 0px 0px 0px;
  background-color: white;
  /* border: 2px solid #ffffff; */
  /* top: 20vh !important; */
  color: black !important;
  overflow: scroll;
}

.statusDataModal {
  width: 647px !important
}

.modalTitle {
  font-size: 22px !important;
  font-weight: 700 !important;
  color: #000000 !important
}

.tableContainer {
  width: 100% !important;
  overflow-x: scroll !important;
}

.greenIndicator {
  background: url("../../assets/images/svgs/sprite-iservice.svg") no-repeat -603px -94px;
  width: 11px;
  height: 11px;
  display: block;
}

.amberIndicator {
  background: url("../../assets/images/svgs/sprite-iservice.svg") no-repeat -554px -95px;
  width: 11px;
  height: 11px;
  display: block;
  fill: #4CAF50;
}

.eyeIcon {
  background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -1269px -138px;
  width: 25px;
  height: 20px;
  display: block;
  margin-top: 2px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.redIndicator {
  width: 11px;
  height: 11px;
  background-color: #C80F56;
  border-radius: 50%;
  display: inline-block;
}

.disconnectedDeviceWrapper {
  display: flex;
  width: 100%;
}

.disconnectedDevice {
  width: 60%;
}

.faliledNetworkChart {
  width: 40%;
  height: 395px;
  max-height: 600px;
  margin-left: 10px;
  background: black;
}

.exportButtonDiv {
  margin-left: 10px;
  width: 110px;
}

.inputDivider {
  width: 1px;
  height: 97%;
  background-color: rgb(34, 34, 34);
  margin-left: 27px;
  margin-right: 18px;
  margin-top: auto;
}

.assetInsightBtn {
  margin-left: 8px;
  height: 30px;
  padding-left: 10px;
  background: white;
  padding-right: 10px;
}

.notConnectedDevicesMain {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}

@media (max-width: 480px) {
  .customerCountHeader {
    display: block !important;
    margin-bottom: 30px;
  }

  .inputContainerDiv {
    display: block;
    padding-top: 0px;
  }

  .mainDiv {
    padding-bottom: 50px !important;
  }

  .datePickerContainerDiv {
    margin-bottom: 10px;
  }

  .tableContainer {
    width: 100%;
    overflow-x: scroll;
  }

  .textArea {
    width: 100% !important;
    height: 40px !important;
    resize: none !important;
    border-radius: 0;
    border: 1px solid #555555;
    font-family: "HM Sans" !important;
    font-weight: 400;
    font-size: 12px;
    color: #555555 !important;
  }

  .buttonStyle {
    width: 70%;
    height: 40px;
    margin-left: 20px;
    cursor: pointer;
    line-height: .560;
  }

  .disconnectedDeviceWrapper {
    display: flex;
    flex-direction: column;
  }

  .disconnectedDevice {
    width: 100%;
  }

  .faliledNetworkChart {
    width: 100%;
    height: 350px;
    margin-left: 10px;
    background: black;
  }

  .dropDownContainerDiv {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 10px;
  }

  .exportButtonDiv {
    width: 100%;
    margin-left: 0;
  }

  .assetInsightBtn {
    width: 100%;
    margin-left: 0;
  }

  .notConnectedDevicesMain{
    display: block;
    margin-top: 10px;
  }

  .faliledNetworkChart{
    margin-top: 10px;
    margin-left: 0;
  }
}

@media (min-width: 481px) and (max-width: 700px) {
  .customerCountHeader {
    display: block;
    margin-bottom: 30px;
  }

  .tableContainer {
    width: 100%;
    overflow-x: scroll;
  }
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  /* Ensure the spinner container takes the full height */
}

@media (min-width: 700px) and (max-width: 900px) {
  .customerCountHeader {
    display: block ;
    margin-bottom: 30px;
  }
}
  
/* @media (max-width: 900px)  {
  .customerCountHeader {
    display: block ;
    margin-bottom: 30px;
  } 
}*/