.exportButton {
    background-color: #222222;
     color: white;
      padding: 4px 20px;
      width: 100%;
}

.exportButton:hover{
    background: #555555 !important;
    color: #fff !important;
    opacity: 1;
    width: 100%;
}