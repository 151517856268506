.dropDownHeader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 3px 12px;
    border: 1px solid #979797;
    color: black;
    border-radius: 4px;
    width: 160px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.verticalDevider {
    height: 15px;
    width: 1px;
    background-color: #979797;
    margin: 0px 0px;
}

.inputWrapper {
    position: absolute;
    width: auto;
    z-index: 1000;
    border: 1px solid #ccc;
    background-color: white;
    /* width: 160px; */
}

.inputSearch {
    /* width: 160px; */
    padding: 3px 12px;
    box-sizing: border-box;
}

.ulStyle {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 300px;
    overflow-y: auto;
    scrollbar-width: none;
}

.inputCheckbox {
    width: 16px;
    height: 16px;
    margin: 10px 5px;
    /* border: 2px solid #000000 !important; */
}

.listLabel {
    display: flex;
    cursor: "pointer";
}


.label {
    margin: 8px 5px;
    font-size: 15px;
    font-family: 'HM Sans';
    color: #000000;
    font-weight: 400;
    width: 100%;
    display: flex;
    white-space: normal;
    line-height: 1.2;
    word-wrap: break-word;
}

.displaySpan {
    width: 100%;
    overflow: scroll;
    scrollbar-width: none;
    max-height: 20px;
}

.clearFilterBtn {
    width: 55px;
    border: 1px solid #696868;
    font-size: 12px;
    padding: 0px 5px;
    background-color: white;
    border-radius: 5px;
    color: #696868;
    margin-left: 10px;
}

.React\Virtualized__List::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.React\Virtualized__List {
    scrollbar-width: none;
}

.disabled {
    opacity: 0.6;
    pointer-events: none;
}

.downArrow {
    background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -1324px -143px;
    width: 13px;
    height: 13px;
    display: block;
    margin-top: 3px;
}

@media(max-width:480px) {

    .dropDownHeader {
        width: 100%;
    }

    .inputWrapper {
        width: 81% !important;
    }

    .inputSearch {
        width: 100%;

    }

    .verticalDevider {
        display: none;
    }
}