body{
  //background-image: url(../../assets/images/mobile/Svgs/backgroundImage.svg) !important;
  font-family: HM Slussen;
  
}
.ant-radio-inner {
  background-color: black; /* Change to the desired background color */
  color: #fff;
}
.adm-card-header {
  display: block !important;
}
.adm-card-body{
  padding: 0px;
}
