.ant-tooltip-inner {
    background-color: white !important;
  }

  /* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 60px;
  background-color: white;
  color: black;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  border: 1px solid black;
  padding-left: 5px;
  padding-right: 5px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  top: 125%; /* Position below the div */
  left: 187%;
  margin-left: -75px; /* Adjust as needed */

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: -12px; /* Position the arrow above the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent white transparent; /* Arrow pointing up */
}

.tooltip .tooltiptext::before {
  content: "";
  position: absolute;
  top: -14px; /* Position the arrow above the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent black transparent; /* Arrow pointing up */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

 /* Tooltip container */
 .userNameTooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.userNameTooltip .userNameTooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: white;
  color: black;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  border: 1px solid black;
  padding-left: 5px;
  padding-right: 5px;

  /* Ensure text wraps properly */
  word-wrap: break-word;
  white-space: normal;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  top: 125%; /* Position below the div */
  left: 50%;
  margin-left: -75px; /* Adjust as needed */

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.userNameTooltip .userNameTooltiptext::after {
  content: "";
  position: absolute;
  top: -12px; /* Position the arrow above the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent white transparent; /* Arrow pointing up */
}

.userNameTooltip .userNameTooltiptext::before {
  content: "";
  position: absolute;
  top: -14px; /* Position the arrow above the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent black transparent; /* Arrow pointing up */
}

/* Show the tooltip text when you mouse over the tooltip container */
.userNameTooltip:hover .userNameTooltiptext {
  visibility: visible;
  opacity: 1;
}

.headingSubPart{
  width: 195px;
height: 29px;
top: 18px;
left: 202px;
gap: 0px;
font-family: HM Sans;
font-size: 18px;
font-weight: 600;
/* line-height: 29.06px; */
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: white;
}

@media(max-width:480px) {
.iconsDiv{
  margin-left: 20px;
}

}