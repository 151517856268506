.mainDiv {
    height: 100vh;
    color: blue;
    padding: 12px 40px;
}

.devider {
    border-top: 1px solid #bbb;
    padding: 10px 0px;
}

.heading {
    width: 40%;
    margin-bottom: 10px;
}

.routePageTitleWrapper {
    padding-bottom: 20px;
    color: #555555;
}

.storeConfigureTitle {
    font-family: HM Sans;
    font-size: 25px !important;
    font-weight: 500;
    margin-bottom: 0px;
    color: #333333;
}

.storeConfigurationMainDiv {
    display: flex;
    position: relative;
    flex-wrap: wrap;
}

.dropDownContainerDiv {
    margin-left: 10px;
}

.breadCrumbsLink {
    font-family: HM Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #555555;
    text-decoration: none;
}

.breadCrumbsLink:hover {
    color: #555555;
}

.headerDiv {
    display: flex;
    justify-content: space-between;
}

.filterFirstHalf{
    display: flex;
}

.tablesContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.smartphonesTableContainer {
    overflow-x: scroll !important;
    overflow-y: hidden;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.applicationsDropDown {
    width: 180;
    height: "32px";
    color: "#6F6F6F";
    font-size: "14px";
    font-weight: "400px";
    font-family: "HM Sans";
}

.iServiceDropDown {
    width: 180;
    height: "32px";
    color: "#6F6F6F";
    font-size: "14px";
    font-weight: "400px";
    font-family: "HM Sans";
}

.brandsDropDown {
    width: 160;
    height: "32px";
    color: "#6F6F6F";
    font-size: "14px";
    font-weight: "400px";
    font-family: "HM Sans";
}

.countryDropDown {
    width: 180;
    height: "32px";
    color: "#6F6F6F";
    font-size: "14px";
    font-weight: "400px";
    font-family: "HM Sans";
}

.storeDropDown {
    width: 160;
    height: "32px";
    color: "#6F6F6F";
    font-size: "14px";
    font-weight: "400px";
    font-family: "HM Sans";
}

.editLogo {
    background-image: url("../../assets/images/svgs/sprite-icons-desktop.svg");
    width: 35px;
    height: 35px;
    display: block;
    background-position: 668px 47px;
    margin: auto;
    margin-right: 40px !important;
    cursor: pointer;
}

.deleteLogo {
    background-image: url("../../assets/images/svgs/sprite-icons-desktop.svg");
    width: 35px;
    height: 35px;
    display: block;
    background-position: 586px 47px;
    margin: auto;
    margin-right: 40px !important;
    cursor: pointer;
}

.closeIcon {
    background: url("../../assets/images/svgs/sprite-icons-desktop.svg") no-repeat -1054px -138px;
    width: 22px;
    height: 22px;
    margin-left: auto;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
}

.btnContainer {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.CancelBtn {
    width: 135px;
    height: 34px;
    border-radius: 3px;
    padding: 10px !important;
    margin-top: 20px;
}

.AddBtn {
    background-color: #222222;
    color: white;
    width: 135px;
    height: 34px;
    border-radius: 3px;
    padding: 10px !important;
    margin-top: 20px;
    margin-right: 20px;
}

.AddBtn:hover {
    background-color: #222222 !important;
    color: white !important;
    width: 135px;
    height: 34px;
    border-radius: 3px;
    padding: 10px !important;
    margin-top: 20px;
}

.iconbutton {
    position: absolute;
    right: 21%;
    top: -10px;
    color: #3f3c3c;
    background: none !important;
    border: none !important;
    cursor: pointer;
    font-size: 20px;
    /* Adjust the size as needed */
    padding-left: 10px;
    margin-right: 18px;
}

.popoverContainer {
    /* width: 710px; */
    z-index: 1000;
    background-color: white;
    padding: 10px;
    border: 1px solid #000000;
    border-radius: 4px;
    overflow: hidden;
}

.msgHeading {
    display: flex;
    font-family: "HM Sans";
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    justify-content: center;
    width: 100%;
}

ant-popover {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.filterDiv {
    display: flex;
}

.filterSecondHalf{
    display: flex;
}

/* Responsive Design */
@media (max-width: 1250px) {
    .headerDiv {
        display: block;
    }
}

@media (max-width: 1024px) {
    .storeConfigurationMainDiv {
        flex-direction: column;
    }

    .tablesContainer {
        flex-direction: column;
    }

    .smartphonesTableContainer {
        width: 100% !important;
    }

    .dropDownContainerDiv {
        margin-left: 0;
        margin-bottom: 10px;
    }
}

@media (max-width: 600px) {
    .redLabel {
        position: absolute;
        right: 2%;
        color: #CB3011;
        font-size: 30px;
    }

    .greenLabel {
        position: absolute;
        left: 2%;
        color: #076C35;
        font-size: 30px;
    }

    .mainDiv {
        padding: 12px 20px;
    }

    .storeConfigureTitle {
        font-size: 20px !important;
    }

    .breadCrumbsLink {
        font-size: 12px;
    }

    .dropDownContainerDiv {
        margin-left: 0;
        margin-bottom: 10px;
    }

}

@media (max-width: 480px) {
    .mainDiv {
        padding: 12px 10px;
    }

    .storeConfigureTitle {
        font-size: 18px !important;
    }

    .breadCrumbsLink {
        font-size: 10px;
    }

    .dropDownContainerDiv {
        margin-left: 0;
        margin-bottom: 10px;
    }
}

@media (min-width: 1307px) {
    .heading {
        width: 40%;
        margin-bottom: 10px;
    }
}

@media (max-width: 1432px) {
    .headerDiv{
        display: block;
    }
}

@media(max-width:1160px) {
    .filterDiv {
        display: block;
    }

    .filterFirstHalf{
        margin-bottom: 10px;
        gap: 10px;

    }

    .filterSecondHalf{
        gap: 10px;
    }
    .heading{
        width: 100vw;
    }
}

@media(max-width: 630px) {
    .filterDiv {
        display: block;
    }

    .filterFirstHalf{
        display: block;
        margin-bottom: 10px;
        gap: 10px;

    }

    .filterSecondHalf{
        display: block;
        gap: 10px;
    }

    .heading{
        width: 100vw;
    }
}