.mainDiv {
    height: 100vh;
    color: blue;
    padding: 12px 40px;
}

.routePageTitleWrapper {
    margin-bottom: 20px;
    color: #555555;
}

.breadCrumbsLink {
    font-family: HM Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #555555;
    text-decoration: none;
}

.musicinstoreHeader {
    display: flex;
    justify-content: space-between;
}

.musicinstoreTitle {
    font-family: 'HM Sans';
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #333333 !important;
    padding-top: 5px;
}

.inputContainerDiv {
    display: flex;
    padding-top: 5px;
    /* gap: 5px; */
}

.dropDownContainer{
    display: flex;
}
.dateAndCountryDiv {
    display: flex;
}

.datePickerContainerDiv {
    width: 160px;
}

.dropDownContainerDiv {
    width: 160px;
    margin-left: "10px";
}

.countryDropDown {
    margin-left: 3px;
    margin-right: 10px;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    /* Ensure the spinner container takes the full height */
}

.breadCrumbsLink:hover {
    color: #555555;
}

.radioBtnsDiv {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: 10px;
    color: black;
}

.radioBtn {
    margin-bottom: 2px;
}

.radioBtnMain {
    display: flex;
    align-items: center;
    gap: 5px;
}

.musicinstoreContent {
    display: flex;
}

.firstHalf {
    width: 30%;
    padding-top: 10px;
}

.secondHalf {
    width: 70%;
}

.totalplayersLabel {
    font-family: HM Slussen Condensed;
    font-weight: 400;
    font-size: 30px;
    line-height: 100%;
    color: black;
    height: 45px;
}

.totalplayersValue {
    font-family: HM Slussen Expanded;
    font-weight: 700;
    font-size: 35px;
    line-height: 100%;
    color: black;
    height: 52px;
    margin-bottom: 10px;
    margin-top: -5px;
}

.OfflineBar {
    position: relative;
    width: 80%;
    background: #DFDFDF;
    height: 12px;
    border-radius: 12px;
}

.OfflineBarVal {
    position: absolute;
    background: #D71010;
    height: 12px;
    border-radius: 12px;
    padding-top: 10px;
}

.offlinelabelvalue {
    width: 78%;
    display: flex;
    justify-content: space-between;
    margin-bottom: -6px;
}

.offlineLabel {
    font-family: HM Sans;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #D71010;
}

.value {
    font-family: HM Slussen Expanded;
    font-weight: 700;
    font-size: 17px;
    line-height: 100%;
    letter-spacing: 0%;
    color: black;
}

.unTrackedlabelvalue{
    width: 78%;
    display: flex;
    justify-content: space-between;
    margin-bottom: -6px;
}

.unTrackedLabel{
    font-family: HM Sans;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #FB8C00;
}

.unTrackedpercentageValues{
    width: 20%;
    font-family: HM Sans;
    font-weight: 700;
    font-size: 19px;
    color: #FB8C00;   
}

.unTrackedBar{
    position: relative;
    width: 80%;
    background: #DFDFDF;
    height: 12px;
    border-radius: 12px;
}

.untrackedBarVal{
    position: absolute;
    background: #FB8C00;
    height: 12px;
    border-radius: 12px;
    padding-top: 10px;
}

.barContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.percentageValues {
    width: 20%;
    font-family: HM Sans;
    font-weight: 700;
    font-size: 19px;
    color: #D71010;
}

.OnlineBar {
    position: relative;
    width: 80%;
    background: #DFDFDF;
    height: 12px;
    border-radius: 12px;
}

.OnlineBarVal {
    position: absolute;
    background: #4CAF50;
    height: 12px;
    border-radius: 12px;
}

.onlinelabelvalue {
    width: 78%;
    display: flex;
    justify-content: space-between;
    margin-bottom: -6px;
}

.onlineLabel {
    font-family: HM Sans;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0%;
    color: #4CAF50;
}

.onlinepercentageValues {
    width: 20%;
    font-family: HM Sans;
    font-weight: 700;
    font-size: 19px;
    color: #4CAF50;
}

.filterIcon {
    background: url("../../assets/images/svgs/sprite-icons-desktop.svg") no-repeat -687px -217px;
    width: 57px;
    height: 25px;
    display: block;
  }

.eyeIcon {
    background: url("../../assets/images/svgs/sprite-iservice-desktop.svg") no-repeat -1269px -138px;
    width: 25px;
    height: 20px;
    display: block;
    margin-top: 2px;
    margin-left: 10px;
    cursor: pointer;
}

.playlistHeading {
    font-family: Roboto;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0%;
    color: black;
    margin-bottom: 6px;
}

.recentPlaylistDiv {
    width: 389;
    height: 312px;
    width: 80%;
    top: 522px;
    left: 61px;
    gap: 12px;
    margin-bottom: 12px;
}

.songMain {
    width: 100%;
    height: 69px;
    display: flex;
    gap: 8px
}

.songImageDiv {
    min-width: 58px;
    width: 58px;
    height: 56px;
    top: 7px;
    left: 7px;
    border-radius: 4px;
    background-color: gray;
    margin: auto;
}

.songDetails {
    width: calc(100% - 58px);
    margin: auto;
}

.songName {
    font-family: Roboto;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: black;
    width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.songDesc {
    font-family: Roboto;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0px;    
    color: black;
    width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    /* Ensure the spinner container takes the full height */
  }

  @media(max-width:1140px){
    .musicinstoreHeader{
        display: block;
    }
  }

  @media(max-width:983px){
    .inputContainerDiv{
        display: block;
    }
    .dropDownContainer{
        margin-top: 10px;
    }

    .musicinstoreContent{
        flex-direction: column-reverse;
        
    }
    .secondHalf{
        width: 100%;
    }

    .firstHalf{
        display: flex;
        width: 100%;
        gap: 20px;
        margin-top: 10px;
    }

    .totalPlayersDiv{
        width: 100%;
    }
  }

  @media(max-width:633px){
    .firstHalf{
        display: block;
    }
    .dropDownContainer{
        display: block;
    }

    .dropDownContainerDiv{
        width: 100%;
    }

    .countryDropDown{
        margin-bottom: 10px;
    }
  }