.container {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heading {
    font-size: xx-large;
    margin-bottom: 5px;
}

.description {
    font-size: medium;
    padding: 0px 20px;
}

.link {
    font-style: underline;
    color: blue;
}