.grammerlycheckbox {
  color: #ffffff !important;
}
.closeIcon {
  background-position: -134px -136px !important;
  width: 12px;
  height: 12px;
  margin: 4px;
  cursor: pointer;
}
.spriteUrl {
  background: url("../../assets/images/svgs/ai-companion-sprite.svg") no-repeat;
}
.modalCloseIcon {
  display: flex;
  background-position: -272px -90px !important;
  width: 28px;
  height: 28px;
}
.ModalCloseBtn {
  cursor: pointer;
  position: absolute !important;
  z-index: 1;
  right: -10px !important;
  top: -9px;
}
